import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { getString } from '../../utils/translations';
import { getAsset } from '../../utils';
import { get } from 'lodash';
import { resetChatAction, loadChatHistory, checkChatStatus, stopChatProcessing } from '../../redux/chat/api';
import { useDispatch, useSelector } from 'react-redux';

// Status message mapping
const statusMessages = {
    initializing: getString('status_initializing', 'Preparing to analyze your video...'),
    processing: getString('status_processing', 'Processing video data (this may take a minute)...'),
    in_progress: getString('status_in_progress', 'Analyzing emotions and facial expressions...'),
    processing_follow_up: getString('status_processing_follow_up', 'Processing your follow-up question...')
};

const ChatSidebar = React.forwardRef(({ isOpen, messages, onSendMessage, onClose, isLoading, tableData = [], loadingStatus = 'processing' }, ref) => {
    const [inputValue, setInputValue] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [copiedMessageId, setCopiedMessageId] = useState(null);
    const [showVideoSelector, setShowVideoSelector] = useState(false);
    const [videoFilter, setVideoFilter] = useState('');
    const [filteredVideos, setFilteredVideos] = useState([]);
    const [isComposing, setIsComposing] = useState(false);
    
    const messagesEndRef = useRef(null);
    const inputRef = useRef(null);
    const videoInputRef = useRef(null);
    const videoSelectorRef = useRef(null);
    
    const dispatch = useDispatch();
    const currentVideoId = useSelector(state => state.chat.currentVideoId);
    const chatExists = useSelector(state => state.chat.chatExists);
    
    const handleSubmit = (e) => {
        e.preventDefault();
        if (inputValue.trim()) {
            onSendMessage(inputValue);
            setInputValue('');
            setShowSuggestions(false);
        }
    };
    
    // Add this helper function for calculating string similarity
    const calculateSimilarity = (str1, str2) => {
        // Convert both strings to lowercase for case-insensitive comparison
        const s1 = str1.toLowerCase();
        const s2 = str2.toLowerCase();
        
        // Direct substring match gets high score
        if (s2.includes(s1)) {
            return 0.9 + (s1.length / s2.length) * 0.1; // Longer matches get slightly higher score
        }
        
        // Check for word-by-word partial matches
        const words = s1.split(/\s+/).filter(word => word.length > 1);
        if (words.length > 0) {
            const matchCount = words.filter(word => s2.includes(word)).length;
            if (matchCount > 0) {
                return 0.7 * (matchCount / words.length);
            }
        }
        
        // Calculate Levenshtein distance for fuzzy matching
        const track = Array(s2.length + 1).fill(null).map(() => 
            Array(s1.length + 1).fill(null));
        
        for (let i = 0; i <= s1.length; i += 1) {
            track[0][i] = i;
        }
        
        for (let j = 0; j <= s2.length; j += 1) {
            track[j][0] = j;
        }
        
        for (let j = 1; j <= s2.length; j += 1) {
            for (let i = 1; i <= s1.length; i += 1) {
                const indicator = s1[i - 1] === s2[j - 1] ? 0 : 1;
                track[j][i] = Math.min(
                    track[j][i - 1] + 1, // deletion
                    track[j - 1][i] + 1, // insertion
                    track[j - 1][i - 1] + indicator, // substitution
                );
            }
        }
        
        // Convert distance to similarity score (0-1)
        const maxLength = Math.max(s1.length, s2.length);
        if (maxLength === 0) return 0;
        
        const distance = track[s2.length][s1.length];
        const similarity = 1 - (distance / maxLength);
        
        return similarity * 0.5; // Scale down the fuzzy match score
    };
    
    // Function to filter videos with fuzzy search
    const filterVideos = useCallback((filterText) => {
        if (!Array.isArray(tableData) || tableData.length === 0) {
            setFilteredVideos([]);
            return;
        }
        
        if (!filterText.trim()) {
            // Show all videos if no filter
            setFilteredVideos(
                tableData
                    .filter(video => get(video, 'name', ''))
                    .slice(0, 10) // Limit to prevent very long lists
            );
            return;
        }
        
        // Filter videos with fuzzy search
        const validVideos = tableData.filter(video => get(video, 'name', ''));
        
        const scoredVideos = validVideos.map(video => {
            const videoName = get(video, 'name', '');
            const score = calculateSimilarity(filterText.toLowerCase(), videoName.toLowerCase());
            return { video, score, name: videoName };
        });
        
        // Filter and sort by relevance
        const relevantVideos = scoredVideos
            .filter(item => item.score > 0.2) // Minimum similarity threshold
            .sort((a, b) => b.score - a.score)
            .slice(0, 10); // Limit results
            
        setFilteredVideos(relevantVideos.map(item => item.video));
    }, [tableData]);
    
    // Initialize filtered videos when dropdown opens
    useEffect(() => {
        if (showVideoSelector) {
            filterVideos(videoFilter);
            // Focus the search input
            setTimeout(() => {
                if (videoInputRef.current) {
                    videoInputRef.current.focus();
                }
            }, 100);
        }
    }, [showVideoSelector, filterVideos]);
    
    // Handle clicks outside video selector to close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (videoSelectorRef.current && !videoSelectorRef.current.contains(event.target)) {
                setShowVideoSelector(false);
            }
        };
        
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    
    // Function to add selected video to message
    const addVideoToMessage = (video) => {
        const videoName = get(video, 'name', '');
        const videoId = get(video, 'id') || get(video, 'videoId');
        
        setInputValue(prev => {
            // If we already have content, add a space before the video name
            const prefix = prev.trim() ? `${prev.trim()} ` : '';
            return `${prefix}"${videoName}"`;
        });
        
        setShowVideoSelector(false);
        
        // Focus the main input after selection
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 100);
    };
    
    // Handle filter change
    const handleVideoFilterChange = (e) => {
        const value = e.target.value;
        setVideoFilter(value);
        filterVideos(value);
    };
    
    // Toggle video selector
    const toggleVideoSelector = () => {
        setShowVideoSelector(prev => !prev);
        setVideoFilter('');
    };
    
    // Get a valid video name for examples
    const getExampleVideoName = () => {
        const firstVideo = tableData?.find(video => get(video, 'name'));
        return get(firstVideo, 'name', 'Video Name');
    };
    
    // Modify the autoGrowTextarea function to also handle scroll class
    const autoGrowTextarea = (element) => {
        if (!element) return;
        
        // Reset height to allow shrinking
        element.style.height = 'auto';
        
        // Set height based on scrollHeight, but clamp it
        const newHeight = Math.min(Math.max(element.scrollHeight, 24), 240);
        element.style.height = `${newHeight}px`;
        
        // Check if text contains line breaks or exceeds single line height
        const hasLineBreaks = element.value.includes('\n');
        const exceedsLineHeight = element.scrollHeight > 50; // Approximate single line height with padding
        
        if (hasLineBreaks || exceedsLineHeight) {
            element.classList.add('multi-line');
        } else {
            element.classList.remove('multi-line');
        }
    };
    
    // Update the handleInputChange function to include auto-growing
    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        
        // Auto-grow textarea
        autoGrowTextarea(e.target);
        
        // Show suggestions after user has typed something
        if (value.trim().length > 0) {
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };
    
    // Add this useEffect to handle auto-sizing when component mounts or input value changes
    useEffect(() => {
        if (inputRef.current) {
            autoGrowTextarea(inputRef.current);
        }
    }, [inputValue]);
    
    // Scroll to bottom whenever messages change
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);
    
    // Focus input when sidebar opens
    useEffect(() => {
        if (isOpen && inputRef.current) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 300); // Delay to wait for sidebar animation
        }
    }, [isOpen]);
    
    // Add this function to handle copying text to clipboard
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(
            () => {
                // Success feedback will be handled by component state
            },
            (err) => {
                console.error('Could not copy text: ', err);
            }
        );
    };
    
    // Add this function to handle copy button click
    const handleCopy = (messageContent, messageId) => {
        // Get plain text from HTML content
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = messageContent;
        const textContent = tempDiv.textContent || tempDiv.innerText || '';
        
        copyToClipboard(textContent);
        
        // Show copied feedback
        setCopiedMessageId(messageId);
        setTimeout(() => {
            setCopiedMessageId(null);
        }, 2000); // Reset after 2 seconds
    };
    
    // Add this useEffect to check for existing chat when the sidebar opens
    useEffect(() => {
        if (isOpen) {
            dispatch(checkChatStatus()).then(exists => {
                if (exists) {
                    dispatch(loadChatHistory());
                }
            });
        }
    }, [isOpen, dispatch]);
    
    // Add this function to handle reset
    const handleResetChat = () => {
        if (window.confirm('Are you sure you want to reset the chat? This will clear all messages and allow you to start a new conversation.')) {
            dispatch(resetChatAction());
        }
    };

    console.log("ai_assistant_welcome", "Hello! \nI'm here to assist you!")
    
    // Add these handlers for IME composition events
    const handleCompositionStart = () => {
        setIsComposing(true);
    };

    const handleCompositionEnd = () => {
        setIsComposing(false);
    };
    
    // Modify the existing handleKeyDown function
    const handleKeyDown = (e) => {
        // Only submit form on Enter without Shift key and when not in IME composition
        if (e.key === 'Enter' && !e.shiftKey && !isComposing) {
            e.preventDefault(); // Prevent adding a new line
            handleSubmit(e); // Submit the form
        }
    };

    // Add a handler for stopping chat processing
    const handleStopProcessing = () => {
        dispatch(stopChatProcessing());
    };

    return (
        <ChatSidebarContainer ref={ref} isOpen={isOpen}>
            <ChatHeader>
                <div>
                    <h3>{getString("ai_video_assistant", "AI Video Assistant")}</h3>
                    {currentVideoId && (
                        <CurrentVideoLabel>
                            {getString("discussing_video", "Discussing")}: {
                                tableData.find(v => get(v, 'id') === currentVideoId || get(v, 'videoId') === currentVideoId)?.name || 
                                getString("unknown_video", "Unknown video")
                            }
                        </CurrentVideoLabel>
                    )}
                </div>
                <HeaderButtons>
                    {chatExists && (
                        <ResetButton onClick={handleResetChat}>
                            <img src={getAsset('icons/reset.svg')} alt="Reset" />
                        </ResetButton>
                    )}
                    <CloseButton onClick={onClose}>
                        <img src={getAsset('icons/xIcon.svg')} alt="Close" />
                    </CloseButton>
                </HeaderButtons>
            </ChatHeader>
            
            <MessagesContainer>
                {messages.length === 0 ? (
                    <WelcomeMessage style={{ whiteSpace: 'pre-line' }}>
                        <h4 style={{ whiteSpace: 'pre-line' }}>{getString("ai_assistant_welcome", "Welcome to the Video Analysis Assistant")}</h4>
                        {tableData?.length > 0 && (
                            <ExampleQuestions>
                                <p style={{ whiteSpace: 'pre-line' }}>{getString("question_for_example")}</p>
                            </ExampleQuestions>
                        )}
                    </WelcomeMessage>
                ) : (
                    <>
                        {messages.map((msg, idx) => (
                            <MessageBubble 
                                key={idx} 
                                isUser={msg.sender === 'user'} 
                                isSystem={msg.sender === 'system'}
                                isSuccess={msg.sender === 'success'}
                            >
                                <MessageContent dangerouslySetInnerHTML={{ __html: formatMessageContent(msg.content) }} />
                                
                                {/* Add copy button for assistant messages */}
                                {msg.sender === 'assistant' && (
                                    <CopyButton 
                                        className={`copy-button ${copiedMessageId === idx ? 'copied' : ''}`}
                                        onClick={() => handleCopy(msg.content, idx)}
                                    >
                                        <img 
                                            src={getAsset(copiedMessageId === idx ? 'icons/check.svg' : 'icons/copy.svg')} 
                                            alt={copiedMessageId === idx ? "Copied" : "Copy"} 
                                        />
                                    </CopyButton>
                                )}
                            </MessageBubble>
                        ))}
                        {isLoading && (
                            <StatusMessage>
                                <LoadingIndicator>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                </LoadingIndicator>
                                <StatusWithEstimate status={loadingStatus} />
                            </StatusMessage>
                        )}
                    </>
                )}
               
                <div ref={messagesEndRef} />
            </MessagesContainer>
            
            <ChatInputForm onSubmit={handleSubmit}>
                {showVideoSelector && (
                    <VideoSelectorContainer ref={videoSelectorRef}>
                        <VideoSelectorHeader>
                            <h4>{getString("select_video", "Select a Video")}</h4>
                            <CloseButton onClick={() => setShowVideoSelector(false)}>
                                <img src={getAsset('icons/xIcon.svg')} alt="Close" />
                            </CloseButton>
                        </VideoSelectorHeader>
                        
                        <VideoSearchInput
                            ref={videoInputRef}
                            type="text"
                            value={videoFilter}
                            onChange={handleVideoFilterChange}
                            placeholder={getString("search_videos", "Search videos...")}
                        />
                        
                        <VideoList>
                            {filteredVideos.length > 0 ? (
                                filteredVideos.map((video, index) => (
                                    <VideoItem 
                                        key={index} 
                                        onClick={() => addVideoToMessage(video)}
                                    >
                                        {get(video, 'name', '')}
                                    </VideoItem>
                                ))
                            ) : (
                                <NoVideosMessage>
                                    {videoFilter ? 
                                        getString("no_matching_videos", "No matching videos found") : 
                                        getString("no_videos_available", "No videos available")}
                                </NoVideosMessage>
                            )}
                        </VideoList>
                    </VideoSelectorContainer>
                )}
                
                <InputContainer>
                    <ChatInput 
                        ref={inputRef}
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        onCompositionStart={handleCompositionStart}
                        onCompositionEnd={handleCompositionEnd}
                        placeholder={
                            isLoading 
                                ? getString("waiting_for_response") 
                                : chatExists && currentVideoId 
                                    ? getString("ask_followup_question") 
                                    : getString("ask_about_video")
                        }
                        disabled={isLoading}
                        rows="1" // Start with one row
                    />
                    
                    {!isLoading && (
                        <VideoButton 
                            type="button" 
                            onClick={toggleVideoSelector} 
                            title={getString("add_video", "Add Video")}
                        >
                            <img src={getAsset('icons/attach.svg')} alt="Add Video" />
                        </VideoButton>
                    )}
                </InputContainer>
                
                {isLoading ? (
                    <SendButton type="button" onClick={handleStopProcessing}>
                        <img src={getAsset('icons/stop.svg')} alt="Stop" />
                    </SendButton>
                ) : (
                    <SendButton type="submit" disabled={!inputValue.trim() || isLoading}>
                        <img src={getAsset('icons/Sent.svg')} alt="Send" />
                    </SendButton>
                )}
            </ChatInputForm>
        </ChatSidebarContainer>
    );
});

// Helper to format message content with enhanced markdown support
const formatMessageContent = (content) => {
    if (!content) return '';
    
    // Convert markdown-like syntax to HTML
    let formatted = content
        // Headers (h1, h2, h3, h4)
        .replace(/^# (.*?)$/gm, '<h1>$1</h1>')
        .replace(/^## (.*?)$/gm, '<h2>$1</h2>')
        .replace(/^### (.*?)$/gm, '<h3>$1</h3>')
        .replace(/^#### (.*?)$/gm, '<h4>$1</h4>')
        
        // Bold text
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
        .replace(/__(.*?)__/g, '<strong>$1</strong>')
        
        // Italic text
        .replace(/\*(.*?)\*/g, '<em>$1</em>')
        .replace(/_(.*?)_/g, '<em>$1</em>')
        
        // Code blocks (multiline)
        .replace(/```([\s\S]*?)```/g, '<pre><code>$1</code></pre>')
        
        // Inline code
        .replace(/`([^`]+)`/g, '<code>$1</code>')
        
        // Blockquotes
        .replace(/^> (.*?)$/gm, '<blockquote>$1</blockquote>')
        
        // Horizontal rule
        .replace(/^---$/gm, '<hr>')
        
        // Unordered lists
        .replace(/^- (.*?)$/gm, '<li>$1</li>')
        .replace(/^\* (.*?)$/gm, '<li>$1</li>')
        
        // Ordered lists
        .replace(/^\d+\. (.*?)$/gm, '<li>$1</li>')
        
        // Links
        .replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>')
        
        // Line breaks
        .replace(/\n/g, '<br>');
    
    // Wrap lists with ul/ol tags
    if (formatted.includes('<li>')) {
        // Find consecutive list items and wrap them
        formatted = formatted.replace(/(<li>.*?<\/li>)+/g, '<ul>$&</ul>');
    }
    
    return formatted;
};

const ChatSidebarContainer = styled.div`
    position: fixed;
    top: 5em; /* Match the DashboardContainer margin-top */
    left: 0;
    width: 400px;
    height: calc(100vh - 5em);
    background-color: white;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    z-index: 99;
    transform: translateX(${props => props.isOpen ? '0' : '-100%'});
    transition: transform 0.3s ease-in-out;
`;

const ChatHeader = styled.div`
    padding: 16px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    h3 {
        margin: 0;
        font-family: Rubik;
        color: #2E384D;
    }

    h4 {
       line-height: 0.8;
    }
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    
    img {
        width: 20px;
        height: 20px;
    }
`;

const MessagesContainer = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const WelcomeMessage = styled.div`
    text-align: center;
    padding: 24px;
    
    img {
        width: 64px;
        height: 64px;
        margin-bottom: 16px;
    }
    
    h4 {
        font-family: Rubik;
        color: #2E384D;
        margin-bottom: 8px;
        line-height: 0.8;
    }
    
    p {
        color: #8798AD;
    }
`;

const MessageBubble = styled.div`
    padding: 12px 16px 32px 16px;
    border-radius: 18px;
    max-width: ${props => {
        if (props.isSuccess) return '80%'; // Light green for success
        if (props.isSystem) return '80%'; // Change from orange to light blue for system messages
        return props.isUser ? '80%' : '100%'; // Blue for user, light gray for assistant
    }};
    align-self: ${props => props.isUser ? 'flex-end' : 'flex-start'};
    background-color: ${props => {
        if (props.isSuccess) return '#E6F4EA'; // Light green for success
        if (props.isSystem) return '#EBF5FF'; // Change from orange to light blue for system messages
        return props.isUser ? '#536EEC' : '#f4f6fc'; // Blue for user, light gray for assistant
    }};
    color: ${props => {
        if (props.isSuccess) return '#1E8E3E'; // Dark green for success text
        if (props.isSystem) return '#0066CC'; // Change from orange to blue for system messages
        return props.isUser ? 'white' : 'unset'; // White for user, dark for assistant
    }};
    font-family: 'Open Sans';
    line-height: 1.4;
    position: relative;
    
    &:hover ${props => !props.isUser ? '.copy-button' : ''} {
        opacity: 1;
    }
`;

const MessageContent = styled.div`
    ul, ol {
        margin: 8px 0;
        padding-left: 20px;
    }
    
    li {
        margin-bottom: 4px;
    }
    
    h1, h2, h3, h4 {
        margin: 12px 0 8px 0;
        font-family: Rubik;
        color: inherit;
    }
    
    h1 {
        font-size: 1.5em;
    }
    
    h2 {
        font-size: 1.3em;
    }
    
    h3 {
        font-size: 1.1em;
    }
    
    h4 {
        font-size: 1em;
    }
    
    strong {
        font-weight: 600;
    }
    
    em {
        font-style: italic;
    }
    
    code {
        font-family: monospace;
        background-color: rgba(0, 0, 0, 0.05);
        padding: 2px 4px;
        border-radius: 3px;
        font-size: 0.9em;
    }
    
    pre {
        background-color: rgba(0, 0, 0, 0.05);
        padding: 10px;
        border-radius: 5px;
        overflow-x: auto;
        margin: 10px 0;
    }
    
    pre code {
        background-color: transparent;
        padding: 0;
    }
    
    blockquote {
        border-left: 3px solid #536EEC;
        padding-left: 10px;
        margin: 10px 0;
        color: rgba(0, 0, 0, 0.6);
    }
    
    a {
        color: #536EEC;
        text-decoration: none;
    }
    
    a:hover {
        text-decoration: underline;
    }
    
    hr {
        border: none;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        margin: 15px 0;
    }
`;

const ChatInputForm = styled.form`
    display: flex;
    padding: 16px;
    border-top: 1px solid #f0f0f0;
`;

const InputContainer = styled.div`
    position: relative;
    flex: 1;
    display: flex;
`;

const ChatInput = styled.textarea`
    flex: 1;
    padding: 12px 16px;
    padding-right: 40px; /* Make room for the video button */
    border: 1px solid #e0e0e0;
    border-radius: 24px;
    font-family: 'Open Sans';
    resize: none; /* Prevent manual resizing */
    min-height: 24px; /* Single line height */
    max-height: 240px; /* Approximately 10 lines of text */
    overflow: hidden; /* Hide overflow by default - NO SCROLLBAR */
    line-height: 1.5; /* For consistent line height */
    
    /* Remove default textarea appearance */
    appearance: none;
    outline: none;
    
    /* Apply overflow only when we need to scroll */
    &.multi-line {
        overflow-y: auto;
    }
    
    /* Across all browsers, hide scrollbars unless hovering */
    &::-webkit-scrollbar {
        width: 6px;
        background: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
    }
    
    /* Firefox */
    scrollbar-width: none;
    
    &.multi-line {
        scrollbar-width: thin;
    }
    
    &:focus {
        outline: none;
        border-color: #536EEC;
    }
    
    &:disabled {
        background-color: #f9f9f9;
        cursor: not-allowed;
    }
`;

const SendButton = styled.button`
    background-color: #536EEC;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
    
    img {
        width: 20px;
        height: 20px;
    }
`;

const LoadingIndicator = styled.div`
    display: flex;
    align-items: center;
    margin-right: 8px;
    
    .dot {
        width: 8px;
        height: 8px;
        margin: 0 4px;
        background-color: #536EEC;
        border-radius: 50%;
        opacity: 0.7;
        animation: pulse 1.5s infinite ease-in-out;
    }
    
    .dot:nth-child(2) {
        animation-delay: 0.5s;
    }
    
    .dot:nth-child(3) {
        animation-delay: 1s;
    }
    
    @keyframes pulse {
        0%, 100% {
            transform: scale(1);
            opacity: 0.7;
        }
        50% {
            transform: scale(1.2);
            opacity: 1;
        }
    }
`;

const LoadingDots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  span {
    animation: loadingDots 1.4s infinite both;
    font-size: 16px;
    margin: 0 1px;
    
    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
  
  @keyframes loadingDots {
    0% { opacity: 0.2; }
    20% { opacity: 1; }
    100% { opacity: 0.2; }
  }
`;

const StatusMessage = styled.div`
    display: flex;
    align-items: center;
    align-self: flex-start;
    padding: 12px 16px;
    background-color: #f8f9fa;
    border-radius: 18px;
    color: #6c757d;
    font-size: 0.9em;
    margin-top: 8px;
`;

const SuggestionsContainer = styled.div`
    padding: 12px;
    background-color: #f8f9fa;
    border-radius: 8px;
    margin: 8px 0;
`;

const SuggestionText = styled.p`
    color: #6c757d;
    font-size: 0.9em;
    margin-bottom: 8px;
`;

const SuggestionsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;

const SuggestionItem = styled.button`
    background-color: #e9ecef;
    border: none;
    border-radius: 16px;
    padding: 4px 12px;
    font-size: 0.9em;
    color: #495057;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    &:hover {
        background-color: #dee2e6;
    }
    
    ${props => props.relevance && `
        background-color: ${getRelevanceColor(props.relevance)};
        color: ${props.relevance > 70 ? '#fff' : '#495057'};
    `}
`;

const ExampleQuestions = styled.div`
    margin-top: 16px;
    padding: 12px;
    background-color: #f8f9fa;
    border-radius: 8px;
    
    p {
        color: #6c757d;
        margin-bottom: 8px;
    }
    
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    
    li {
        color: #495057;
        margin-bottom: 8px;
        font-size: 0.9em;
        padding: 4px 8px;
        cursor: pointer;
        border-radius: 4px;
        transition: background-color 0.2s;
        
        &:hover {
            background-color: #e9ecef;
        }
        
        &:before {
            content: "•";
            color: #536EEC;
            margin-right: 8px;
        }
    }
`;

// Add a component for showing status with estimated time
const StatusWithEstimate = ({ status }) => {
    // Calculate estimated time based on status
    let estimate = '';
    
    if (status === 'initializing') {
        estimate = '(approx. 5-10 seconds)';
    } else if (status === 'processing') {
        estimate = '(approx. 20-30 seconds)';
    } else if (status === 'in_progress') {
        estimate = '(approx. 30-60 seconds)';
    } else if (status === 'processing_follow_up') {
        estimate = '(approx. 10-15 seconds)';
    }
    
    return (
        <EstimateText>
            {statusMessages[status] || getString("status_processing", "Processing your request...")}
            {estimate && <TimeEstimate>{estimate}</TimeEstimate>}
        </EstimateText>
    );
};

// Add these styled components
const EstimateText = styled.div`
    display: flex;
    flex-direction: column;
`;

const TimeEstimate = styled.span`
    font-size: 0.8em;
    opacity: 0.7;
    margin-top: 4px;
`;

const CopyButton = styled.button`
    position: absolute;
    bottom: 8px;
    right: 8px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease, background-color 0.2s ease;
    
    &:hover {
        background-color: #f0f0f0;
    }
    
    &.copied {
        background-color: #4CAF50;
        opacity: 1;
        
        img {
            filter: brightness(0) invert(1);
        }
    }
    
    img {
        width: 14px;
        height: 14px;
    }
`;

const RelevanceBadge = styled.span`
    font-size: 0.75em;
    margin-left: 8px;
    opacity: 0.7;
    font-weight: 600;
`;

// Add this helper function to get color based on relevance
const getRelevanceColor = (relevance) => {
    if (relevance > 90) return '#28a745'; // High match - green
    if (relevance > 70) return '#17a2b8'; // Good match - blue
    if (relevance > 50) return '#ffc107'; // Medium match - yellow
    return '#e9ecef'; // Low match - default gray
};

const HeaderButtons = styled.div`
    display: flex;
    align-items: center;
`;

const ResetButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 12px;
    
    img {
        width: 18px;
        height: 18px;
    }
    
    &:hover {
        opacity: 0.7;
    }
`;

const CurrentVideoLabel = styled.div`
    font-size: 0.8em;
    color: #6c757d;
    margin-top: 4px;
`;

const VideoButton = styled.button`
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
        width: 20px;
        height: 20px;
        opacity: 0.6;
    }
    
    &:hover img {
        opacity: 1;
    }
`;

const VideoSelectorContainer = styled.div`
    position: absolute;
    bottom: 70px;
    left: 16px;
    width: calc(100% - 32px);
    max-height: 300px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    z-index: 100;
`;

const VideoSelectorHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid #f0f0f0;
    
    h4 {
        margin: 0;
        font-size: 14px;
        color: #2E384D;
    }
`;

const VideoSearchInput = styled.input`
    padding: 10px 16px;
    border: none;
    border-bottom: 1px solid #f0f0f0;
    font-family: 'Open Sans';
    font-size: 14px;
    
    &:focus {
        outline: none;
        border-color: #536EEC;
    }
`;

const VideoList = styled.div`
    overflow-y: auto;
    padding: 8px 0;
    max-height: 200px;
`;

const VideoItem = styled.div`
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
    color: #495057;
    
    &:hover {
        background-color: #f8f9fa;
    }
`;

const NoVideosMessage = styled.div`
    padding: 16px;
    text-align: center;
    color: #6c757d;
    font-size: 14px;
    font-style: italic;
`;

const StopButton = styled.button`
    background-color: #536EEC;
    color: white;
    border: none;
    border-radius: 24px;
    padding: 0 16px;
    height: 40px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s;
    
    &:hover {
        background-color:rgb(126, 145, 233);
    }
    
    img {
        width: 16px;
        height: 16px;
        margin-right: 6px;
    }
`;


export default ChatSidebar; 