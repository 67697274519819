import React, { Component } from 'react';
import PopUp from 'reactjs-popup';

import 'reactjs-popup/dist/index.css';

import styled from "styled-components";
import colors from "../styles/colors";


import { get } from "lodash";
import { getAsset } from "../utils"
import { getString } from "../utils/translations"
import { STATUS } from '../constants';
import Video from "../components/Video"
import Popup from './Popup';
import { setVideoTracker } from "../redux/videos"
import { pushToTableData } from "../redux/table"
import { saveVideoData } from "../redux/videos/api"
import { connect } from "react-redux";
import {parseVideoItem} from "../parsers/solo";
import {calcSufficientData} from "../redux/table/utils";

class VideoAnalyzePopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cancelAnalyzeProcessPopupOpen: false,
            analyzing: false,
            backgroundProcessing: false,
            previewMode: false,
            finalized: false,
            tempVideoId: null,
            triggerFinalization: false
        }

        this.videoRef = React.createRef();
    }

    resetState = () => {
        this.setState({ cancelAnalyzeProcessPopupOpen: false, analyzing: false, backgroundProcessing: false, previewMode: false, finalized: false, tempVideoId: null })
    }

    handleCloseButton = () => {
        this.setState({ cancelAnalyzeProcessPopupOpen: true })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {savingVideo, videoAnalyzeForLiveStream} = this.props
        const {analyzing, backgroundProcessing} = this.state

        if(videoAnalyzeForLiveStream && !savingVideo && prevProps.savingVideo){
          //  this.setState({previewMode: true})
        }

        //if((prevState.analyzing || prevState.backgroundProcessing) && !videoAnalyzeForLiveStream && !analyzing && !backgroundProcessing){
      //      this.setState({previewMode: true})
      // }

    }

    onAnalyzeEnd = (video) => {
        const {pushToTableData} = this.props
        // When analysis ends and finalization is complete, we're already in preview mode
        this.setState({
            analyzing: false,
            backgroundProcessing: false,
            previewMode: true,
            finalized: true,
            video: video
        }, () => {
            console.log("VideoAnalyzePopup state updated:", this.state);
        });

        console.log("pushToTableData", video)
        let parsedVideoItem = parseVideoItem(video);
        parsedVideoItem.sufficientData = calcSufficientData(video, "video")
        pushToTableData(parsedVideoItem);

        // Call any additional handlers if needed
        if (this.props.onAnalyzeEnd) {
            this.props.onAnalyzeEnd(video);
        }
    }

    handleSaveButton = async () => {
        const { setVideoTracker, tracker, title, date, pushToTableData, saveVideoData, houseId, videoAnalyzeForLiveStream } = this.props
        const { previewMode, finalized } = this.state

        if (videoAnalyzeForLiveStream) {
            if (!previewMode) {
                this.setState({ triggerFinalization: true });
            } else {
                setVideoTracker([])
                this.props.closeHandler()
                this.resetState()
                return
            }
        } else if (previewMode) {
            // We're in preview mode after finalization - video is already saved
            // Just close the popup and reset
            setVideoTracker([]);
            this.props.closeHandler();
            this.resetState();
        } else {
            // This case shouldn't happen in new flow, but keeping for compatibility
            const tempId = Date.now();
            const name = title;
            const seconds = tracker.length ? get(tracker[tracker.length - 1], "currentTime", 0) : 0;
            const videoDate = date;
            const status = STATUS.PROCESSING;

            pushToTableData({ tempId, name, seconds, videoDate, status });
            saveVideoData({ tracker, name, date: videoDate, seconds, houseId });

            setVideoTracker([]);
            this.props.closeHandler();
            this.resetState();
        }
    }

    renderHeader = () => {
        const { title, videoAnalyzeForLiveStream, savingVideo } = this.props
        const {analyzing, backgroundProcessing, previewMode } = this.state
        return (<Header>
            {
                (videoAnalyzeForLiveStream && previewMode) ? <div></div> :
                (<Button src={getAsset("icons/xIcon.svg")} onClick={this.handleCloseButton} width={25} />)
            }
            <Title>{title}</Title>
            {((analyzing || backgroundProcessing) && !videoAnalyzeForLiveStream) || savingVideo ? (
                    <Button src={getAsset("loader.svg")} onClick={()=>{}} width={30}/>) :
                <Button
                    src={getAsset("icons/vIcon.svg")}
                    onClick={this.handleSaveButton}
                    width={30}
                    title={previewMode ? getString("close") : getString("save")}
                />}
        </Header>)
    }

    renderBody = (height) => {
        return (<Body>
            <Video
                videoAnalyzeForLiveStream={this.props.videoAnalyzeForLiveStream}
                videoFile={this.props.videoFile}
                faceApiModel={this.props.faceApiModel}
                overTimeChart={true}
                height={height}
                title={this.props.title}
                date={this.props.date}
                houseId={this.props.houseId}
                setAnalyzing={(analyzing)=>{
                    this.setState({analyzing})
                }}
                setBackgroundProcessing={(processing)=>{this.setState({backgroundProcessing: processing})}}
                backgroundProcessing={false}
                detectionInterval={0}
                previewMode={this.state.previewMode}
                useMaskModel={this.props.useMaskModel}
                onAnalyzeEnd={this.onAnalyzeEnd}
                triggerFinalization={this.state.triggerFinalization}
                onFinalizationComplete={() => {
                    this.setState({ triggerFinalization: false });
                }}
            />
        </Body>)
    }

    handleDeleteAnalyzedData = () => {
        const { setVideoTracker } = this.props
        this.setState({ cancelAnalyzeProcessPopupOpen: false })
        setVideoTracker([])
        this.props.closeHandler()
    }


    handleKeepAnalyzedData = () => {
        this.setState({ cancelAnalyzeProcessPopupOpen: false })
    }

    render() {

        const height = window.screen.height * 85 / 100
        const width = height * 1.15

        const { cancelAnalyzeProcessPopupOpen } = this.state
        const { isOpen, closeHandler, closeOnDocumentClick } = this.props

        return (
            <PopUp
                position="center"
                closeOnDocumentClick={closeOnDocumentClick}
                open={isOpen}
                onClose={closeHandler}
                contentStyle={{
                    width,
                    height,
                    padding: 0,
                    borderRadius: 9,
                    boxShadow: "0px 1.13559px 14.1949px rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {this.renderHeader()}

                {this.renderBody(height - headerHeight)}

                {cancelAnalyzeProcessPopupOpen && <Popup
                    closeOnDocumentClick={false}
                    isOpen={cancelAnalyzeProcessPopupOpen}
                    title={getString("delete_analyze_video_popup_title")}
                    description={getString("delete_analyze_video_popup_message")}
                    footerButtons={[{ label: getString("delete"), onPress: this.handleDeleteAnalyzedData, background: colors.lipstick },
                    { label: getString("keep_data"), onPress: this.handleKeepAnalyzedData, background: colors.green }]}
                />}
            </PopUp>
        );
    }
}

const mapStateToProps = ({ video }) => ({
    tracker: video.tracker,
    savingVideo: video.savingVideo
});

const mapDispatchToProps = {
    setVideoTracker,
    pushToTableData,
    saveVideoData
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoAnalyzePopup);

const Title = styled.div`
    font-family: Open Sans;
    font-weight: 900;
    font-size: 27px;
    color: #536EEC;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

let headerHeight = 40;
const Header = styled.div `
            height: ${headerHeight}px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 7px;
            padding-right: 7px;
            `

const Body = styled.div`
            flex: 1;
            display: flex;
            flex-direction: column;
            `

const Button = styled.img`
    cursor: pointer;
`
