import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PopUp from 'reactjs-popup';

import 'reactjs-popup/dist/index.css';

import styled from "styled-components";
import colors from "../styles/colors";
import Lottie from 'react-lottie';

import { get } from "lodash";

import { getString } from "../utils/translations"
import { Fragment } from 'react';

class Popup extends Component {

    render() {

        const { isOpen, closeHandler, title, description, content, footerButtons, loading, loader, error, closeOnDocumentClick, showAllItems } = this.props

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: loader,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        let contentStyle = {
            width: 335,
            borderRadius: 9,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: 70
        };

        return (
            <div>
                <PopUp
                    position="center"
                    closeOnDocumentClick={closeOnDocumentClick}
                    open={isOpen}
                    onClose={closeHandler}
                    contentStyle={contentStyle}
                >
                    <PopupTitle>{title}</PopupTitle>

                    {description && <PopupMessage>{description}</PopupMessage>}

                    {this.props.children ? (
                        this.props.children
                    ) : React.isValidElement(content) ? (
                        content
                    ) : get(content, "length", null) && (
                        <div style={{ height: this.props.showAll ? "auto" : "270px", overflowY: "auto" }}>
                            {content.map((c) => (
                                <Fragment key={c.id}>
                                    <PopUpContent onClick={c.onClick ? () => c.onClick(c.current) : () => {}}>
                                        {getString(c.title)}
                                    </PopUpContent>
                                    {c.subContent && <PopUpSubContent>{c.subContent}</PopUpSubContent>}
                                </Fragment>
                            ))}
                        </div>
                    )}

                    <ErrorMessage >{error}</ErrorMessage>

                    <Footer>
                        {footerButtons.map(b =>
                            <FooterButton onClick={b.onPress} style={{ background: b.background ? b.background : colors.blue, color: "#fff" }} key={b.label}> {loading ? (
                                < Lottie
                                    options={defaultOptions}
                                    height={100}
                                    width={100}
                                />) : (
                                b.label
                            )}
                            </FooterButton>
                        )}
                    </Footer>
                </PopUp>
            </div >
        );
    }
}

Popup.propTypes = {
    isOpen: PropTypes.bool,
    closeHandler: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    content: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            title: PropTypes.string,
            onClick: PropTypes.func,
            subContent: PropTypes.string
        })),
        PropTypes.node
    ]),
    footerButtons: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        onPress: PropTypes.func
    })),
    loading: PropTypes.bool,
    loader: PropTypes.any,

};

const PopupTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6875em;
  line-height: 2.3125em;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0190110625em;
  text-transform: capitalize; 
  /* Main 1 */
  color: #536EEC;
`

const PopUpSubContent = styled.div`
    margin-bottom: 13px;
    max-width: 20em;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    word-break: break-all;
    display: flex;
    align-items: center;
    justify-content: center;
    `

const PopUpContent = styled.div`
    cursor: pointer;
    background: rgba(224, 231, 255, 0.2);

    margin-bottom: 13px;

    border: 1px solid #536EEC;
    border-radius: 5px;
    height: 3.1875em;
    width: 14.125em;
    line-height: 3.1875em;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 1.3125em;
    //line-height: 1.8125em;
    text-align: center;
    vertical-align: middle;

    color: #2E384D;
    `

const PopupMessage = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3125em;
  line-height: 1.4em;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #303030;
  margin-bottom: 0.7em;
`

const Footer = styled.div`
  display: flex;
  position: absolute;
  bottom: -0.125em;
  left: -0.0625em;
  right: -0.0625em;
  height: 3.125em;
  box-shadow: 0em 0.070974375em 0.88718125em rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 9px 9px;
`

const FooterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 2.6em;


  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1.19643125em;
  line-height: 1.625em;
  text-align: center;
  letter-spacing: 0.03351575em;

  color: #131313;
  
  text-transform: capitalize; 

  flex: 1
`

const ErrorMessage = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9375em;
  line-height: 1.35em;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing:0.0190110625em;

  /* Main 2 */

  color: #CC1D6C;
`

export default Popup;
