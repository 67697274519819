import { getTableItem } from "../models/tableData"
import { getTopItem } from "../models/topData"
import { SCREENS, TAG_CONTEXT } from "../constants"

export const parseUsersTableData = (users) => {
    let parsedData = []
    for (let user of users) {
        const itemData = {
            userId: user.user.id,
            name: user.user.name || `id: ${user.user.externalId}`,
            alerts: null,
            tags: user.user.Tags || [],
            lastSeen: user.user.lastSeen,
            sessions: user.sessions,
            type: null,
            seconds: user.seconds,
            trend: null,
            wellbeing: user.wellbeing,
            energy: user.energy,
            valence: user.mood,
            energy_ps: user.energy,
            valence_ps: user.mood,
            stress: user.stress,
            engagement: user.engagement,
            happiness: user.happiness,
            calmness: user.calmness,
            sad: user.sad,
            angry: user.angry,
            fearful: user.fearful,
            surprised: user.surprised,
            disgusted: user.disgusted,
            tagContext: TAG_CONTEXT.USER
        }
        const parsed = getTableItem(itemData, SCREENS.USERS)
        parsedData.push(parsed)
    }
    return parsedData
}

export const parseSessionsTableData = (sessions) => {
    let parsedData = []
    for (let session of sessions) {
        const itemData = {
            sessionId: session.sessionId,
            userId: session.userId,
            comment: session.comment,
            seconds: session.seconds,
            createdAt: session.createdAt,
            updatedAt: session.updatedAt,
            contentType: session.type,
            wellbeing: session.wellbeing,
            energy: session.energy,
            valence: session.mood,
            energy_ps: session.energy,
            valence_ps: session.mood,
            stress: session.stress,
            engagement: session.engagement,
            happiness: session.happy,
            calmness: session.calm,
            sad: session.sad,
            angry: session.angry,
            fearful: session.fearful,
            surprised: session.surprised,
            disgusted: session.disgusted,
            user: session.user,
            tags: session.tags || [],
            unitCount: session.unitCount || 0,
        }
        const parsed = getTableItem(itemData, SCREENS.SESSIONS)
        parsedData.push(parsed)
    }
    return parsedData
}

export const parseContentTableData = (content) => {
    let parsedData = []
    for (let c of content) {
        const itemData = {
            unitId: c.unitId,
            contentId: c.ContentId,
            sessionUnitId: c.sessionUnitId,
            MediaResourceId: null,
            angry: c.angry,
            author: null,
            calmness: c.calm,
            createdAt: c.createdAt,
            disgusted: c.disgusted,
            duration: null,
            energy: c.energy,
            fearful: c.fearful,
            happiness: c.happy,
            title: c.title,
            contentType: null,
            sad: c.sad,
            seconds: c.seconds,
            sessionId: c.sessionId,
            spanish: null,
            stress: c.stress,
            surprised: c.surprised,
            valence: c.mood,
            wellbeing: c.wellbeing,
            japanese: null,
            chinese: null,
            malay: null,
            arabic: null,
            hebrew: null,
            russian: null,
            engagement: c.engagement,
            interest: c.interest
        }
        const parsed = getTableItem(itemData, SCREENS.CONTENT)
        parsedData.push(parsed)
    }
    return parsedData
}

export const parseUsersTopData = (users) => {
    const itemData = {
        activeUsers: users.activeUsers,
        angry: users.angry,
        calmness: users.calmness,
        costSaving: null,
        disgusted: users.disgusted,
        energy: users.energy,
        energy_ps: users.energy,
        engagement: users.engagement,
        fearful: users.fearful,
        happiness: users.happiness,
        overTimeEmotions: users.overTimeEmotions,
        overTimeEmotionsByDay: users.overTimeEmotionsByDay,
        overTimeEmotionsByDayOfMonth: users.overTimeEmotionsByDayOfMonth,
        overTimeEmotionsByHour: users.overTimeEmotionsByHour,
        lastMonthsOT: users.lastMonthsOT,
        lastWeekOT: users.lastWeekOT,
        sad: users.sad,
        seconds: users.seconds,
        sessions: users.sessions,
        stress: users.stress,
        surprised: users.surprised,
        trend: null,
        valence: users.mood,
        valence_ps: users.mood,
        wellbeing: users.wellbeing,
        scores: users.scores
    }
    const parsed = getTopItem(itemData, SCREENS.USERS)
    return parsed
}

export const parseSessionsTopData = (session) => {
    const itemData = {
        activeUsers: session.activeUsers,
        angry: session.angry,
        calmness: session.calmness,
        costSaving: null,
        disgusted: session.disgusted,
        energy: session.energy,
        energy_ps: session.energy,
        engagement: session.engagement,
        fearful: session.fearful,
        happiness: session.happiness,
        overTimeEmotions: session.overTimeEmotions,
        overTimeEmotionsByDay: session.overTimeEmotionsByDay,
        overTimeEmotionsByDayOfMonth: session.overTimeEmotionsByDayOfMonth,
        overTimeEmotionsByHour: session.overTimeEmotionsByHour,
        user: session.user,
        sad: session.sad,
        seconds: session.seconds,
        sessions: session.sessions,
        stress: session.stress,
        surprised: session.surprised,
        trend: session.trend,
        valence: session.mood,
        valence_ps: session.mood,
        wellbeing: session.wellbeing,
        interest: session.interest,
        userName: session.user.name
    }
    const parsed = getTopItem(itemData, SCREENS.SESSIONS)
    return parsed
}

export const parseContentTopData = (content) => {
    const itemData = {
        userId: null,
        activeUsers: content.activeUsers,
        angry: content.angry,
        calmness: content.calm,
        comment: content.comment,
        costSaving: null,
        createdAt: content.createdAt,
        disgusted: content.disgusted,
        energy: content.energy,
        energy_ps: content.energy,
        fearful: content.fearful,
        happiness: content.happy,
        contentType: null,
        overTimeEmotions: content.overTimeEmotions,
        sad: content.sad,
        seconds: content.seconds,
        sessionId: content.sessionId,
        sessions: content.sessions,
        stress: content.stress,
        surprised: content.surprised,
        valence: content.mood,
        valence_ps: content.mood,
        wellbeing: content.wellbeing,
        engagement: content.engagement,
        interest: content.interest,
        userName: content.userName
    }
    const parsed = getTopItem(itemData, SCREENS.CONTENT)
    return parsed
}

export const parseVideosTopData = (data) => {
    return data
}

export const parseVideosTableData = (data) => {
    return data
}
