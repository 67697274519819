import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isChatOpen: false,
    messages: [],
    isLoading: false,
    error: null,
    loadingStatus: 'processing', // One of: initializing, processing, in_progress, processing_follow_up
    currentVideoId: null,
    chatExists: false
};

const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        toggleChat: (state) => {
            state.isChatOpen = !state.isChatOpen;
        },
        addUserMessage: (state, action) => {
            state.messages.push({
                sender: 'user',
                content: action.payload,
                timestamp: new Date().toISOString()
            });
        },
        addAIMessage: (state, action) => {
            state.messages.push({
                sender: 'assistant',
                content: action.payload,
                timestamp: new Date().toISOString()
            });
        },
        addSystemMessage: (state, action) => {
            state.messages.push({
                sender: 'system',
                content: action.payload,
                timestamp: new Date().toISOString()
            });
        },
        addSuccessMessage: (state, action) => {
            state.messages.push({
                sender: 'success',
                content: action.payload,
                timestamp: new Date().toISOString()
            });
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setLoadingStatus: (state, action) => {
            state.loadingStatus = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setCurrentVideoId: (state, action) => {
            state.currentVideoId = action.payload;
        },
        setChatExists: (state, action) => {
            state.chatExists = action.payload;
        },
        clearMessages: (state) => {
            state.messages = [];
        },
        resetChat: (state) => {
            state.messages = [];
            state.error = null;
            state.currentVideoId = null;
            state.chatExists = false;
        }
    }
});

export const { 
    toggleChat, 
    addUserMessage, 
    addAIMessage, 
    addSystemMessage,
    addSuccessMessage,
    setLoading,
    setLoadingStatus,
    setError,
    setCurrentVideoId,
    setChatExists,
    clearMessages,
    resetChat
} = chatSlice.actions;

export default chatSlice.reducer; 