import firebase from 'firebase/compat/app';
import "firebase/compat/analytics";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/storage";
import { firebaseConfig } from "../constants/config";

firebase.initializeApp(firebaseConfig);

export const getVideoUrl = async (path, houseId) => {
    let videoUrl = await firebase.storage().ref(path).getDownloadURL()
    return videoUrl
}
export default firebase;

export const analytics = firebase.analytics();

export const refreshToken = async () => {
    const user = firebase.auth().currentUser;
    if (user) {
        const idToken = await user.getIdToken(true);
        localStorage.setItem("userToken", idToken)
    }
}
