import { getTableItem } from "../models/tableData"
import { getTopItem } from "../models/topData"
import { SCREENS, TAG_CONTEXT } from "../constants"

export const parseUsersTableData = (users) => {
    let parsedData = []
    for (let user of users) {
        const itemData = {
            userId: user.resident.id,
            name: user.resident.name,
            alerts: user.resident.ResidentAlert,
            tags: user.resident.Tags || [],
            lastSeen: user.resident.lastSeen,
            sessions: user.sessions,
            type: user.resident.type,
            seconds: user.seconds,
            trend: user.trend,
            wellbeing: user.wellbeing,
            energy: user.energy,
            valence: user.valence,
            energy_ps: user.energy_ps,
            valence_ps: user.valence_ps,
            stress: user.stress,
            engagement: user.engagement,
            interest: user.interest,
            happiness: user.happiness_ps,
            calmness: user.calmness_ps,
            sad: user.sad_ps,
            angry: user.angry_ps,
            fearful: user.fearful_ps,
            surprised: user.surprised_ps,
            disgusted: user.disgusted_ps,
            tagContext: TAG_CONTEXT.RESIDENT
        }
        const parsed = getTableItem(itemData, SCREENS.USERS)
        parsedData.push(parsed)
    }
    return parsedData
}

export const parseSessionsTableData = (sessions) => {
    let parsedData = []
    for (let session of sessions) {
        const itemData = {
            sessionId: session.sessionId,
            userId: session.ResidentId,
            userName: session.resident.name,
            comment: session.comment,
            seconds: session.seconds,
            createdAt: session.createdAt,
            updatedAt: session.updatedAt,
            contentType: session.mediaType,
            wellbeing: session.wellbeing,
            energy: session.energy,
            valence: session.valence,
            energy_ps: session.energy_ps,
            valence_ps: session.valence_ps,
            stress: session.stress,
            engagement: session.engagement,
            happiness: session.happiness_ps,
            calmness: session.calmness_ps,
            sad: session.sad_ps,
            angry: session.angry_ps,
            fearful: session.fearful_ps,
            surprised: session.surprised_ps,
            disgusted: session.disgusted_ps,
            interest: session.interest,
            tags: session.tags || []
        }
        const parsed = getTableItem(itemData, SCREENS.SESSIONS)
        parsedData.push(parsed)
    }
    return parsedData
}

export const parseContentTableData = (content) => {
    let parsedData = []
    for (let c of content) {
        const itemData = {
            contentId: c.contentId,
            sessionUnitId: c.sessionUnitId,
            MediaResourceId: c.MediaResourceId,
            angry: c.angry,
            author: c.author,
            calmness: c.calmness,
            createdAt: c.createdAt,
            disgusted: c.disgusted,
            duration: c.duration,
            energy: c.energy,
            fearful: c.fearful,
            happiness: c.happiness,
            title: c.mediaTitle,
            contentType: c.mediaType,
            sad: c.sad,
            seconds: c.seconds,
            sessionId: c.sessionId,
            spanish: c.spanish,
            stress: c.stress,
            surprised: c.surprised,
            valence: c.valence,
            wellbeing: c.wellbeing,
            japanese: c.japanese,
            chinese: c.chinese,
            malay: c.malay,
            arabic: c.arabic,
            hebrew: c.hebrew,
            russian: c.russian,
            engagement: c.engagement,
            interest: c.interest
        }
        const parsed = getTableItem(itemData, SCREENS.CONTENT)
        parsedData.push(parsed)
    }
    return parsedData
}

export const parseUsersTopData = (users) => {
    const itemData = {
        activeUsers: users.activeUsers,
        angry: users.angry_ps,
        calmness: users.calmness_ps,
        costSaving: users.costSaving,
        disgusted: users.disgusted_ps,
        energy: users.energy,
        energy_ps: users.energy_ps,
        engagement: users.engagement,
        fearful: users.fearful_ps,
        happiness: users.happiness_ps,
        overTimeEmotions: users.overTimeEmotions,
        overTimeEmotionsByDay: users.overTimeEmotionsByDay,
        overTimeEmotionsByDayOfMonth: users.overTimeEmotionsByDayOfMonth,
        overTimeEmotionsByHour: users.overTimeEmotionsByHour,
        lastMonthsOT: users.lastMonthsOT,
        lastWeekOT: users.lastWeekOT,
        sad: users.sad_ps,
        seconds: users.seconds,
        sessions: users.sessions,
        stress: users.stress,
        surprised: users.surprised_ps,
        trend: users.trend,
        valence: users.valence,
        valence_ps: users.valence_ps,
        wellbeing: users.wellbeing,
        scores: users.scores
    }
    console.log("itemData",itemData)
    const parsed = getTopItem(itemData, SCREENS.USERS)
    console.log("parsed",parsed)
    return parsed
}

export function parseVideoItem(video) {
    const itemData = {
        HouseId: video.HouseId,
        angry: video.angry,
        tags: video.Tags || [],
        createdAt: video.createdAt,
        disgusted: video.disgusted,
        energy: video.energy,
        engagement: video.engagement,
        fearful: video.fearful,
        happy: video.happy,
        videoId: video.id,
        interest: video.interest,
        valence: video.mood,
        name: video.name,
        neutral: video.neutral,
        overTime: video.overTime,
        sad: video.sad,
        seconds: video.seconds,
        stress: video.stress,
        surprised: video.surprised,
        tracker: video.tracker,
        updatedAt: video.updatedAt,
        videoDate: video.videoDate,
        wellbeing: video.wellbeing,
        algoVersion: video.algoVersion,
        videoPath: video.videoUrl
    }
    return itemData;
}

export const parseVideosTableData = (data) => {
    let parsedData = []
    for (let d of data) {
        const itemData = parseVideoItem(d);
        parsedData.push(itemData)
    }
    return parsedData
}

export const parseVideosTopData = (data) => {
    const itemData = {
        angry: data.angryAvg_ps,
        calmness: data.calmnessAvg_ps,
        disgusted: data.disgustedAvg_ps,
        energy: data.energyAvg_ps,
        engagement: data.engagement,
        fearful: data.fearfulAvg_ps,
        happiness: data.happinessAvg_ps,
        interest: data.interest,
        overTimeEmotions: data.overTimeEmotions,
        overTimeEmotionsByDay: data.overTimeEmotionsByDay,
        overTimeEmotionsByDayOfMonth: data.overTimeEmotionsByDayOfMonth,
        overTimeEmotionsByHour: data.overTimeEmotionsByHour,
        sad: data.sadAvg_ps,
        seconds: parseInt(data.seconds),
        sessions: data.sessions,
        stress: data.stress,
        surprised: data.surprisedAvg_ps,
        valence: data.valenceAvg_ps,
        wellbeing: data.wellbeing,
        videos: data.totalVideos,
        samples: parseInt(data.total_samples),
        lastWeekOT: data.lastWeekOT,
        lastMonthOT: data.lastMonthsOT,
        lastMonthsOT: data.lastMonthsOT
    }

    return itemData
}

export const parseSessionsTopData = (sessions) => {
    const itemData = {
        activeUsers: sessions.activeUsers,
        angry: sessions.angry_ps,
        calmness: sessions.calmness_ps,
        costSaving: sessions.costSaving,
        disgusted: sessions.disgusted_ps,
        energy: sessions.energy,
        energy_ps: sessions.energy_ps,
        engagement: sessions.engagement,
        fearful: sessions.fearful_ps,
        happiness: sessions.happiness_ps,
        overTimeEmotions: sessions.overTimeEmotions,
        overTimeEmotionsByDay: sessions.overTimeEmotionsByDay,
        overTimeEmotionsByDayOfMonth: sessions.overTimeEmotionsByDayOfMonth,
        overTimeEmotionsByHour: sessions.overTimeEmotionsByHour,
        lastMonthsOT: sessions.lastMonthsOT,
        lastWeekOT: sessions.lastWeekOT,
        user: sessions.resident,
        sad: sessions.sad_ps,
        seconds: sessions.seconds,
        sessions: sessions.sessions,
        stress: sessions.stress,
        surprised: sessions.surprised_ps,
        trend: sessions.trend,
        valence: sessions.valence,
        valence_ps: sessions.valence_ps,
        wellbeing: sessions.wellbeing
    }
    const parsed = getTopItem(itemData, SCREENS.SESSIONS)
    return parsed
}

export const parseContentTopData = (content) => {
    const itemData = {
        userId: content.ResidentId,
        userName: content.userName,
        activeUsers: content.activeUsers,
        angry: content.angry_ps,
        calmness: content.calmness_ps,
        comment: content.comment,
        costSaving: content.costSaving,
        createdAt: content.createdAt,
        disgusted: content.disgusted_ps,
        energy: content.energy,
        energy_ps: content.energy_ps,
        fearful: content.fearful_ps,
        happiness: content.happiness_ps,
        contentType: content.mediaType,
        overTimeEmotions: content.overTimeEmotions,
        sad: content.sad_ps,
        seconds: content.seconds,
        sessionId: content.sessionId,
        sessions: content.sessions,
        stress: content.stress,
        surprised: content.surprised_ps,
        valence: content.valence,
        valence_ps: content.valence_ps,
        wellbeing: content.wellbeing
    }
    const parsed = getTopItem(itemData, SCREENS.CONTENT)
    return parsed
}
