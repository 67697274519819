import { createBrowserHistory } from 'history'
import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router'

import translationsReducer from "./translations"
import topReducer from "./top"
import tableReducer from "./table"
import dateReducer from "./date"
import houseReducer from "./house"
import videoReducer from "./videos"
import billingReducer from "./billing";
import orgReducer from "./organization";
import groupsReducer from "./groups";
import aiUnitsLimitReducer from "./aiUnitsLimit";
import faceIdReducer from "./faceIdSlice";
import chatReducer from "./chat";

export const history = createBrowserHistory()

export const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    house: houseReducer,
    translations: translationsReducer,
    top: topReducer,
    table: tableReducer,
    date: dateReducer,
    video: videoReducer,
    billing: billingReducer,
    organization: orgReducer,
    faceId: faceIdReducer,
    groups: groupsReducer,
    aiUnitsLimit: aiUnitsLimitReducer,
    chat: chatReducer
})
