import firebase from "./firebase"
import "firebase/compat/remote-config";
import {initConfig} from "solo-common";

let HAPPINESS_MIN_IN = 0;
let HAPPINESS_MIN_OUT = 0;
let HAPPINESS_MAX_IN = 0.35;
let HAPPINESS_MAX_OUT = 1;
let HAPPINESS_CERTAINTY_THRESHOLD = 0.9

let NEUTRAL_MIN_IN = 0;
let NEUTRAL_MIN_OUT = 0;
let NEUTRAL_MAX_IN = 0.35;
let NEUTRAL_MAX_OUT = 1;
let NEUTRAL_CERTAINTY_THRESHOLD = 0.9

let EMOTIONAL_MIN_IN = 0;
let EMOTIONAL_MIN_OUT = 0;
let EMOTIONAL_MAX_IN = 0.35;
let EMOTIONAL_MAX_OUT = 1;
let SADNESS_CERTAINTY_THRESHOLD = 0.5
let ANGRY_CERTAINTY_THRESHOLD = 0.5

let SOLO_WEB_PASSWORD = "";

let BASELINE_DURATION_SECONDS = 20;

let INITIAL_PLAYLIST_SIZE = 5;

let MAX_VAL_PROGRESS_BAR = 100;
let MAX_VAL_PROGRESS_BAR_RES = 50;

//baseline avg
let BASELINE_HAPPINESS_MIN_IN = 0;
let BASELINE_HAPPINESS_MIN_OUT = 0;
let BASELINE_HAPPINESS_MAX_IN = 0.35;
let BASELINE_HAPPINESS_MAX_OUT = 1;
let BASELINE_HAPPINESS_CERTAINTY_THRESHOLD = 0.9

let BASELINE_NEUTRAL_MIN_IN = 0;
let BASELINE_NEUTRAL_MIN_OUT = 0;
let BASELINE_NEUTRAL_MAX_IN = 0.35;
let BASELINE_NEUTRAL_MAX_OUT = 1;
let BASELINE_NEUTRAL_CERTAINTY_THRESHOLD = 0.9

let BASELINE_EMOTIONAL_MIN_IN = 0;
let BASELINE_EMOTIONAL_MIN_OUT = 0;
let BASELINE_EMOTIONAL_MAX_IN = 0.35;
let BASELINE_EMOTIONAL_MAX_OUT = 1;
let BASELINE_SADNESS_CERTAINTY_THRESHOLD = 0.5
let BASELINE_ANGRY_CERTAINTY_THRESHOLD = 0.5

let CONVERSION_FACTOR_CALMNESS = 1;

let STRESS_HIGH = 0.7
let STRESS_MEDIUM = 0.4
let STRESS_LOW = 0.2

let INTEREST_HIGH = 0.8
let INTEREST_MEDIUM = 0.45

let ENGAGEMENT_HIGH = 0.8
let ENGAGEMENT_MEDIUM = 0.45

let WELLBEING_HIGH = 0.7
let WELLBEING_MEDIUM = 0.3

let MOOD_HIGH = 0.7
let MOOD_MEDIUM = 0.3

let ENERGY_HIGH = 0.7
let ENERGY_MEDIUM = 0.3

let OVER_TIME_MIN_SUFFICIENT_PERCENT = 5

let DASHBOARD_TIME_FILTER = "MONTH"

let EMOTION_PROGRESS_PERCENT = 60

let ENERGY_CONVERSION_FACTOR_DISGUSTED = 0.5;
let ENERGY_CONVERSION_FACTOR_SAD = 0.5;
let ENERGY_CONVERSION_FACTOR_ANGRY = 0.5;
let ENERGY_CONVERSION_FACTOR_FEARFUL = 0.5;
let ENERGY_CONVERSION_FACTOR_SURPRISED = 0.5;
let ENERGY_CONVERSION_FACTOR_HAPPINESS = 0.5;
let ENERGY_CONVERSION_FACTOR_NEUTRAL = 0.5;

let VALENCE_CONVERSION_FACTOR_NEUTRAL = 0.5;
let VALENCE_CONVERSION_FACTOR_DISGUSTED = 0.5;
let VALENCE_CONVERSION_FACTOR_SAD = 0.5;
let VALENCE_CONVERSION_FACTOR_ANGRY = 0.5;
let VALENCE_CONVERSION_FACTOR_FEARFUL = 0.5;
let VALENCE_CONVERSION_FACTOR_SURPRISED = 0.5;
let VALENCE_CONVERSION_FACTOR_HAPPINESS = 0.5;


let WELLBEING_CONVERSION_FACTOR_HAPPINESS = 0.5;
let WELLBEING_CONVERSION_FACTOR_NEUTRAL = 0.5;
let WELLBEING_CONVERSION_FACTOR_ANGRY = 0.5;
let WELLBEING_CONVERSION_FACTOR_FEAR = 0.5;
let WELLBEING_CONVERSION_FACTOR_SAD = 0.5;
let WELLBEING_CONVERSION_FACTOR_SURPRISED = 0.5;
let WELLBEING_CONVERSION_FACTOR_DISGUSTED = 0.5;

let STRESS_CONVERSION_FACTOR_HAPPINESS = 0.5;
let STRESS_CONVERSION_FACTOR_SAD = 0.5;
let STRESS_CONVERSION_FACTOR_SURPRISED = 0.5;
let STRESS_CONVERSION_FACTOR_FEAR = 0.5;
let STRESS_CONVERSION_FACTOR_DISGUSTED = 0.5;
let STRESS_CONVERSION_FACTOR_NEUTRAL = 0.5;
let STRESS_CONVERSION_FACTOR_ANGRY = 0.5;

let INTEREST_CONVERSION_FACTOR_HAPPINESS = 0;
let INTEREST_CONVERSION_FACTOR_SAD = 0;
let INTEREST_CONVERSION_FACTOR_SURPRISED = 0;
let INTEREST_CONVERSION_FACTOR_FEAR = 0;
let INTEREST_CONVERSION_FACTOR_DISGUSTED = 0;
let INTEREST_CONVERSION_FACTOR_NEUTRAL = 0;
let INTEREST_CONVERSION_FACTOR_ANGRY = 0;

let ENGAGEMENT_CONVERSION_FACTOR_HAPPINESS = 0;
let ENGAGEMENT_CONVERSION_FACTOR_SAD = 0;
let ENGAGEMENT_CONVERSION_FACTOR_SURPRISED = 0;
let ENGAGEMENT_CONVERSION_FACTOR_FEAR = 0;
let ENGAGEMENT_CONVERSION_FACTOR_DISGUSTED = 0;
let ENGAGEMENT_CONVERSION_FACTOR_NEUTRAL = 0;
let ENGAGEMENT_CONVERSION_FACTOR_ANGRY = 0;
let YAWN_CERTAINTY_THRESHOLD
let YAWN_TIREDNESS_TIME_THRESHOLD
let EYE_CLOSED_CERTAINTY_THRESHOLD
let EYE_CLOSED_ASLEEP_TIME_THRESHOLD
let EYE_BLINKED_CERTAINTY_THRESHOLD
let YAW_ANGLE_ALERT_THRESHOLD
let PITCH_ANGLE_MIN_ALERT_THRESHOLD
let PITCH_ANGLE_MAX_ALERT_THRESHOLD
let YAW_ANGLE_MIN_ALERT_THRESHOLD
let YAW_ANGLE_MAX_ALERT_THRESHOLD
let PITCH_ANGLE_MIN_EMOTION_FILTER_THRESHOLD
let PITCH_ANGLE_MAX_EMOTION_FILTER_THRESHOLD
let YAW_ANGLE_MIN_EMOTION_FILTER_THRESHOLD
let YAW_ANGLE_MAX_EMOTION_FILTER_THRESHOLD
let ROLL_ANGLE_MIN_EMOTION_FILTER_THRESHOLD
let ROLL_ANGLE_MAX_EMOTION_FILTER_THRESHOLD
let FILTER_BOX_SIZE
let HAND_POINTS_ON_FACE_THRESHOLD

let DETECTION_SCORE_THRESHOLD = 0.1

let FACE_ID_MIN_DISTANCE = 200;

let HEAD_ANGLE_EMOTION_FILTER_ACTIVE = false;
let MASK_ON_CERTAINTY_THRESHOLD = 0.5;

let FACE_CERTAINTY_FRAMES = 10;

let BLINK_FREQUENCY_DURATION_SECONDS = 10;
let BLINK_FREQUENCY_FILTER_X_FIRST_SECONDS = 10;

let MAX_VIDEO_UPLOAD_MINUTES = 10;

let VIDEO_BACKGROUND_PROCESSING_MIN_SECONDS = 300;

let VIDEO_DASHBOARD_ENALBLE_REALTIME_ANALYSIS = false;

const remoteConfig = firebase.remoteConfig();

remoteConfig.settings = {
    minimumFetchIntervalMillis: 3600000,
};

remoteConfig.defaultConfig = ({
    emotion_avg_func_min_in: HAPPINESS_MIN_IN,
    emotion_avg_func_max_in: HAPPINESS_MAX_IN,
    emotion_avg_func_min_out: HAPPINESS_MIN_OUT,
    emotion_avg_func_max_out: HAPPINESS_MAX_OUT,
    happiness_certainty_threshold: HAPPINESS_CERTAINTY_THRESHOLD,
    neutral_certainty_threshold: NEUTRAL_CERTAINTY_THRESHOLD,
    neutral_avg_func_min_in: NEUTRAL_MIN_IN,
    neutral_avg_func_max_in: NEUTRAL_MAX_IN,
    neutral_avg_func_min_out: NEUTRAL_MIN_OUT,
    neutral_avg_func_max_out: NEUTRAL_MAX_OUT,
    sadness_certainty_threshold: SADNESS_CERTAINTY_THRESHOLD,
    angry_certainty_threshold: ANGRY_CERTAINTY_THRESHOLD,
    emotional_avg_func_min_in: EMOTIONAL_MIN_IN,
    emotional_avg_func_max_in: EMOTIONAL_MAX_IN,
    emotional_avg_func_min_out: EMOTIONAL_MIN_OUT,
    emotional_avg_func_max_out: EMOTIONAL_MAX_OUT,
    SOLO_WEB_PASSWORD: SOLO_WEB_PASSWORD,
    baseline_duration_seconds: BASELINE_DURATION_SECONDS,
    INITIAL_PLAYLIST_SIZE,
    MAX_VIDEO_UPLOAD_MINUTES,
    VIDEO_DASHBOARD_ENALBLE_REALTIME_ANALYSIS
});


remoteConfig.fetchAndActivate()
    .then(async () => {
        console.log("initialize remote config")
        //region happiness
        HAPPINESS_MIN_IN = await getConfigItem("happiness_avg_func_min_in");
        HAPPINESS_MIN_IN = HAPPINESS_MIN_IN.asNumber();
        HAPPINESS_MAX_IN = await getConfigItem("happiness_avg_func_max_in");
        HAPPINESS_MAX_IN = HAPPINESS_MAX_IN.asNumber();
        HAPPINESS_MIN_OUT = await getConfigItem("happiness_avg_func_min_out");
        HAPPINESS_MIN_OUT = HAPPINESS_MIN_OUT.asNumber();
        HAPPINESS_MAX_OUT = await getConfigItem("happiness_avg_func_max_out");
        HAPPINESS_MAX_OUT = HAPPINESS_MAX_OUT.asNumber();
        HAPPINESS_CERTAINTY_THRESHOLD = await getConfigItem("happiness_certainty_threshold");
        HAPPINESS_CERTAINTY_THRESHOLD = HAPPINESS_CERTAINTY_THRESHOLD.asNumber();
        //endregion happiness

        //region neutral
        NEUTRAL_MIN_IN = await getConfigItem("neutral_avg_func_min_in");
        NEUTRAL_MIN_IN = NEUTRAL_MIN_IN.asNumber();

        NEUTRAL_MAX_IN = await getConfigItem("neutral_avg_func_max_in");
        NEUTRAL_MAX_IN = NEUTRAL_MAX_IN.asNumber();

        NEUTRAL_MIN_OUT = await getConfigItem("neutral_avg_func_min_out");
        NEUTRAL_MIN_OUT = NEUTRAL_MIN_OUT.asNumber();

        NEUTRAL_MAX_OUT = await getConfigItem("neutral_avg_func_max_out");
        NEUTRAL_MAX_OUT = NEUTRAL_MAX_OUT.asNumber();

        NEUTRAL_CERTAINTY_THRESHOLD = await getConfigItem("neutral_certainty_threshold");
        NEUTRAL_CERTAINTY_THRESHOLD = NEUTRAL_CERTAINTY_THRESHOLD.asNumber();
        //endregion neutral

        //region emotional
        EMOTIONAL_MIN_IN = await getConfigItem("emotional_avg_func_min_in");
        EMOTIONAL_MIN_IN = EMOTIONAL_MIN_IN.asNumber();

        EMOTIONAL_MAX_IN = await getConfigItem("emotional_avg_func_max_in");
        EMOTIONAL_MAX_IN = EMOTIONAL_MAX_IN.asNumber();

        EMOTIONAL_MIN_OUT = await getConfigItem("emotional_avg_func_min_out");
        EMOTIONAL_MIN_OUT = EMOTIONAL_MIN_OUT.asNumber();

        EMOTIONAL_MAX_OUT = await getConfigItem("emotional_avg_func_max_out");
        EMOTIONAL_MAX_OUT = EMOTIONAL_MAX_OUT.asNumber();

        SADNESS_CERTAINTY_THRESHOLD = await getConfigItem("sadness_certainty_threshold");
        SADNESS_CERTAINTY_THRESHOLD = SADNESS_CERTAINTY_THRESHOLD.asNumber();

        ANGRY_CERTAINTY_THRESHOLD = await getConfigItem("angry_certainty_threshold");
        ANGRY_CERTAINTY_THRESHOLD = ANGRY_CERTAINTY_THRESHOLD.asNumber();
        //endregion emotional

        SOLO_WEB_PASSWORD = await getConfigItem("solo_web_password");
        SOLO_WEB_PASSWORD = SOLO_WEB_PASSWORD.asString();

        BASELINE_DURATION_SECONDS = await getConfigItem("baseline_duration_seconds");
        BASELINE_DURATION_SECONDS = BASELINE_DURATION_SECONDS.asNumber();

        INITIAL_PLAYLIST_SIZE = await getConfigItem("INITIAL_PLAYLIST_SIZE")
        INITIAL_PLAYLIST_SIZE = INITIAL_PLAYLIST_SIZE.asNumber()

        BASELINE_HAPPINESS_MIN_IN = await getConfigItem("BASELINE_HAPPINESS_MIN_IN");
        BASELINE_HAPPINESS_MIN_IN = BASELINE_HAPPINESS_MIN_IN.asNumber();

        BASELINE_HAPPINESS_MIN_OUT = await getConfigItem("BASELINE_HAPPINESS_MIN_OUT");
        BASELINE_HAPPINESS_MIN_OUT = BASELINE_HAPPINESS_MIN_OUT.asNumber();

        BASELINE_HAPPINESS_MAX_IN = await getConfigItem("BASELINE_HAPPINESS_MAX_IN");
        BASELINE_HAPPINESS_MAX_IN = BASELINE_HAPPINESS_MAX_IN.asNumber();

        BASELINE_HAPPINESS_MAX_OUT = await getConfigItem("BASELINE_HAPPINESS_MAX_OUT");
        BASELINE_HAPPINESS_MAX_OUT = BASELINE_HAPPINESS_MAX_OUT.asNumber();

        BASELINE_HAPPINESS_CERTAINTY_THRESHOLD = await getConfigItem("BASELINE_HAPPINESS_CERTAINTY_THRESHOLD");
        BASELINE_HAPPINESS_CERTAINTY_THRESHOLD = BASELINE_HAPPINESS_CERTAINTY_THRESHOLD.asNumber();

        BASELINE_NEUTRAL_MIN_IN = await getConfigItem("BASELINE_NEUTRAL_MIN_IN");
        BASELINE_NEUTRAL_MIN_IN = BASELINE_NEUTRAL_MIN_IN.asNumber();

        BASELINE_NEUTRAL_MIN_OUT = await getConfigItem("BASELINE_NEUTRAL_MIN_OUT");
        BASELINE_NEUTRAL_MIN_OUT = BASELINE_NEUTRAL_MIN_OUT.asNumber();

        BASELINE_NEUTRAL_MAX_IN = await getConfigItem("BASELINE_NEUTRAL_MAX_IN");
        BASELINE_NEUTRAL_MAX_IN = BASELINE_NEUTRAL_MAX_IN.asNumber();

        BASELINE_NEUTRAL_MAX_OUT = await getConfigItem("BASELINE_NEUTRAL_MAX_OUT");
        BASELINE_NEUTRAL_MAX_OUT = BASELINE_NEUTRAL_MAX_OUT.asNumber();

        BASELINE_NEUTRAL_CERTAINTY_THRESHOLD = await getConfigItem("BASELINE_NEUTRAL_CERTAINTY_THRESHOLD");
        BASELINE_NEUTRAL_CERTAINTY_THRESHOLD = BASELINE_NEUTRAL_CERTAINTY_THRESHOLD.asNumber();

        BASELINE_EMOTIONAL_MIN_IN = await getConfigItem("BASELINE_EMOTIONAL_MIN_IN");
        BASELINE_EMOTIONAL_MIN_IN = BASELINE_EMOTIONAL_MIN_IN.asNumber();

        BASELINE_EMOTIONAL_MIN_OUT = await getConfigItem("BASELINE_EMOTIONAL_MIN_OUT");
        BASELINE_EMOTIONAL_MIN_OUT = BASELINE_EMOTIONAL_MIN_OUT.asNumber();

        BASELINE_EMOTIONAL_MAX_IN = await getConfigItem("BASELINE_EMOTIONAL_MAX_IN");
        BASELINE_EMOTIONAL_MAX_IN = BASELINE_EMOTIONAL_MAX_IN.asNumber();

        BASELINE_EMOTIONAL_MAX_OUT = await getConfigItem("BASELINE_EMOTIONAL_MAX_OUT");
        BASELINE_EMOTIONAL_MAX_OUT = BASELINE_EMOTIONAL_MAX_OUT.asNumber();

        BASELINE_SADNESS_CERTAINTY_THRESHOLD = await getConfigItem("BASELINE_SADNESS_CERTAINTY_THRESHOLD");
        BASELINE_SADNESS_CERTAINTY_THRESHOLD = BASELINE_SADNESS_CERTAINTY_THRESHOLD.asNumber();

        BASELINE_ANGRY_CERTAINTY_THRESHOLD = await getConfigItem("BASELINE_ANGRY_CERTAINTY_THRESHOLD");
        BASELINE_ANGRY_CERTAINTY_THRESHOLD = BASELINE_ANGRY_CERTAINTY_THRESHOLD.asNumber();

        CONVERSION_FACTOR_CALMNESS = await getConfigItem("conversion_factor_calmness");
        CONVERSION_FACTOR_CALMNESS = CONVERSION_FACTOR_CALMNESS.asNumber();

        MAX_VAL_PROGRESS_BAR = await getConfigItem("MAX_VAL_PROGRESS_BAR");
        MAX_VAL_PROGRESS_BAR = MAX_VAL_PROGRESS_BAR.asNumber();

        MAX_VAL_PROGRESS_BAR_RES = await getConfigItem("MAX_VAL_PROGRESS_BAR_RES");
        MAX_VAL_PROGRESS_BAR_RES = MAX_VAL_PROGRESS_BAR_RES.asNumber();

        STRESS_HIGH = await getConfigItem("STRESS_HIGH");
        STRESS_HIGH = STRESS_HIGH.asNumber();

        STRESS_MEDIUM = await getConfigItem("STRESS_MEDIUM");
        STRESS_MEDIUM = STRESS_MEDIUM.asNumber();

        STRESS_LOW = await getConfigItem("STRESS_LOW");
        STRESS_LOW = STRESS_LOW.asNumber();

        INTEREST_HIGH = await getConfigItem("INTEREST_HIGH");
        INTEREST_HIGH = INTEREST_HIGH.asNumber();

        INTEREST_MEDIUM = await getConfigItem("INTEREST_MEDIUM");
        INTEREST_MEDIUM = INTEREST_MEDIUM.asNumber();

        ENGAGEMENT_HIGH = await getConfigItem("ENGAGEMENT_HIGH");
        ENGAGEMENT_HIGH = ENGAGEMENT_HIGH.asNumber();

        ENGAGEMENT_MEDIUM = await getConfigItem("ENGAGEMENT_MEDIUM");
        ENGAGEMENT_MEDIUM = ENGAGEMENT_MEDIUM.asNumber();

        WELLBEING_HIGH = await getConfigItem("WELLBEING_HIGH");
        WELLBEING_HIGH = WELLBEING_HIGH.asNumber();

        WELLBEING_MEDIUM = await getConfigItem("WELLBEING_MEDIUM");
        WELLBEING_MEDIUM = WELLBEING_MEDIUM.asNumber();

        MOOD_HIGH = await getConfigItem("MOOD_HIGH");
        MOOD_HIGH = MOOD_HIGH.asNumber();

        MOOD_MEDIUM = await getConfigItem("MOOD_MEDIUM");
        MOOD_MEDIUM = MOOD_MEDIUM.asNumber();

        ENERGY_HIGH = await getConfigItem("ENERGY_HIGH");
        ENERGY_HIGH = ENERGY_HIGH.asNumber();

        ENERGY_MEDIUM = await getConfigItem("ENERGY_MEDIUM");
        ENERGY_MEDIUM = ENERGY_MEDIUM.asNumber();

        OVER_TIME_MIN_SUFFICIENT_PERCENT = await getConfigItem("OVER_TIME_MIN_SUFFICIENT_PERCENT");
        OVER_TIME_MIN_SUFFICIENT_PERCENT = OVER_TIME_MIN_SUFFICIENT_PERCENT.asNumber();

        DASHBOARD_TIME_FILTER = await getConfigItem("DASHBOARD_TIME_FILTER");
        DASHBOARD_TIME_FILTER = DASHBOARD_TIME_FILTER.asString();

        EMOTION_PROGRESS_PERCENT = await getConfigItem("EMOTION_PROGRESS_PERCENT");
        EMOTION_PROGRESS_PERCENT = EMOTION_PROGRESS_PERCENT.asNumber();

        ENERGY_CONVERSION_FACTOR_DISGUSTED = await getConfigItem("ENERGY_CONVERSION_FACTOR_DISGUSTED", "number");
        ENERGY_CONVERSION_FACTOR_SAD = await getConfigItem("ENERGY_CONVERSION_FACTOR_SAD", "number");
        ENERGY_CONVERSION_FACTOR_ANGRY = await getConfigItem("ENERGY_CONVERSION_FACTOR_ANGRY", "number");
        ENERGY_CONVERSION_FACTOR_FEARFUL = await getConfigItem("ENERGY_CONVERSION_FACTOR_FEARFUL", "number");
        ENERGY_CONVERSION_FACTOR_SURPRISED = await getConfigItem("ENERGY_CONVERSION_FACTOR_SURPRISED", "number");
        ENERGY_CONVERSION_FACTOR_NEUTRAL = await getConfigItem("ENERGY_CONVERSION_FACTOR_NEUTRAL_V2", "number");
        ENERGY_CONVERSION_FACTOR_HAPPINESS = await getConfigItem("ENERGY_CONVERSION_FACTOR_HAPPINESS_V2", "number");

        VALENCE_CONVERSION_FACTOR_NEUTRAL = await getConfigItem("VALENCE_CONVERSION_FACTOR_NEUTRAL", "number");
        VALENCE_CONVERSION_FACTOR_DISGUSTED = await getConfigItem("VALENCE_CONVERSION_FACTOR_DISGUSTED", "number");
        VALENCE_CONVERSION_FACTOR_SAD = await getConfigItem("VALENCE_CONVERSION_FACTOR_SAD", "number");
        VALENCE_CONVERSION_FACTOR_ANGRY = await getConfigItem("VALENCE_CONVERSION_FACTOR_ANGRY", "number");
        VALENCE_CONVERSION_FACTOR_FEARFUL = await getConfigItem("VALENCE_CONVERSION_FACTOR_FEARFUL", "number");
        VALENCE_CONVERSION_FACTOR_SURPRISED = await getConfigItem("VALENCE_CONVERSION_FACTOR_SURPRISED", "number");
        VALENCE_CONVERSION_FACTOR_HAPPINESS = await getConfigItem("VALENCE_CONVERSION_FACTOR_HAPPINESS_V2", "number");

        WELLBEING_CONVERSION_FACTOR_HAPPINESS = await getConfigItem("WELLBEING_CONVERSION_FACTOR_HAPPINESS", "number");
        WELLBEING_CONVERSION_FACTOR_NEUTRAL = await getConfigItem("WELLBEING_CONVERSION_FACTOR_NEUTRAL", "number");
        WELLBEING_CONVERSION_FACTOR_ANGRY = await getConfigItem("WELLBEING_CONVERSION_FACTOR_ANGRY", "number");
        WELLBEING_CONVERSION_FACTOR_FEAR = await getConfigItem("WELLBEING_CONVERSION_FACTOR_FEAR", "number");
        WELLBEING_CONVERSION_FACTOR_SAD = await getConfigItem("WELLBEING_CONVERSION_FACTOR_SAD", "number");
        WELLBEING_CONVERSION_FACTOR_SURPRISED = await getConfigItem("WELLBEING_CONVERSION_FACTOR_SURPRISED", "number");
        WELLBEING_CONVERSION_FACTOR_DISGUSTED = await getConfigItem("WELLBEING_CONVERSION_FACTOR_DISGUSTED", "number");

        STRESS_CONVERSION_FACTOR_HAPPINESS = await getConfigItem("STRESS_CONVERSION_FACTOR_HAPPINESS", "number");
        STRESS_CONVERSION_FACTOR_SAD = await getConfigItem("STRESS_CONVERSION_FACTOR_SAD", "number");
        STRESS_CONVERSION_FACTOR_SURPRISED = await getConfigItem("STRESS_CONVERSION_FACTOR_SURPRISED", "number");
        STRESS_CONVERSION_FACTOR_FEAR = await getConfigItem("STRESS_CONVERSION_FACTOR_FEAR", "number");
        STRESS_CONVERSION_FACTOR_DISGUSTED = await getConfigItem("STRESS_CONVERSION_FACTOR_DISGUSTED", "number");
        STRESS_CONVERSION_FACTOR_NEUTRAL = await getConfigItem("STRESS_CONVERSION_FACTOR_NEUTRAL", "number");
        STRESS_CONVERSION_FACTOR_ANGRY = await getConfigItem("STRESS_CONVERSION_FACTOR_ANGRY", "number");

        INTEREST_CONVERSION_FACTOR_HAPPINESS = await getConfigItem("INTEREST_CONVERSION_FACTOR_HAPPINESS", "number");
        INTEREST_CONVERSION_FACTOR_SAD = await getConfigItem("INTEREST_CONVERSION_FACTOR_SAD", "number");
        INTEREST_CONVERSION_FACTOR_SURPRISED = await getConfigItem("INTEREST_CONVERSION_FACTOR_SURPRISED", "number");
        INTEREST_CONVERSION_FACTOR_FEAR = await getConfigItem("INTEREST_CONVERSION_FACTOR_FEAR", "number");
        INTEREST_CONVERSION_FACTOR_DISGUSTED = await getConfigItem("INTEREST_CONVERSION_FACTOR_DISGUSTED", "number");
        INTEREST_CONVERSION_FACTOR_NEUTRAL = await getConfigItem("INTEREST_CONVERSION_FACTOR_NEUTRAL", "number");
        INTEREST_CONVERSION_FACTOR_ANGRY = await getConfigItem("INTEREST_CONVERSION_FACTOR_ANGRY", "number");

        ENGAGEMENT_CONVERSION_FACTOR_HAPPINESS = await getConfigItem("ENGAGEMENT_CONVERSION_FACTOR_HAPPINESS", "number");
        ENGAGEMENT_CONVERSION_FACTOR_SAD = await getConfigItem("ENGAGEMENT_CONVERSION_FACTOR_SAD", "number");
        ENGAGEMENT_CONVERSION_FACTOR_SURPRISED = await getConfigItem("ENGAGEMENT_CONVERSION_FACTOR_SURPRISED", "number");
        ENGAGEMENT_CONVERSION_FACTOR_FEAR = await getConfigItem("ENGAGEMENT_CONVERSION_FACTOR_FEAR", "number");
        ENGAGEMENT_CONVERSION_FACTOR_DISGUSTED = await getConfigItem("ENGAGEMENT_CONVERSION_FACTOR_DISGUSTED", "number");
        ENGAGEMENT_CONVERSION_FACTOR_NEUTRAL = await getConfigItem("ENGAGEMENT_CONVERSION_FACTOR_NEUTRAL", "number");
        ENGAGEMENT_CONVERSION_FACTOR_ANGRY = await getConfigItem("ENGAGEMENT_CONVERSION_FACTOR_ANGRY", "number");

        YAWN_CERTAINTY_THRESHOLD = await getConfigItem("YAWN_CERTAINTY_THRESHOLD", "number");
        YAWN_TIREDNESS_TIME_THRESHOLD = await getConfigItem("YAWN_TIREDNESS_TIME_THRESHOLD", "number");
        EYE_CLOSED_CERTAINTY_THRESHOLD = await getConfigItem("EYE_CLOSED_CERTAINTY_THRESHOLD", "number");
        EYE_CLOSED_ASLEEP_TIME_THRESHOLD = await getConfigItem("EYE_CLOSED_ASLEEP_TIME_THRESHOLD", "number");
        EYE_BLINKED_CERTAINTY_THRESHOLD = await getConfigItem("EYE_BLINKED_CERTAINTY_THRESHOLD", "number");
        YAW_ANGLE_ALERT_THRESHOLD = await getConfigItem("YAW_ANGLE_ALERT_THRESHOLD", "number");
        PITCH_ANGLE_MIN_ALERT_THRESHOLD = await getConfigItem("PITCH_ANGLE_MIN_ALERT_THRESHOLD", "number");
        PITCH_ANGLE_MAX_ALERT_THRESHOLD = await getConfigItem("PITCH_ANGLE_MAX_ALERT_THRESHOLD", "number");
        YAW_ANGLE_MIN_ALERT_THRESHOLD = await getConfigItem("YAW_ANGLE_MIN_ALERT_THRESHOLD", "number");
        YAW_ANGLE_MAX_ALERT_THRESHOLD = await getConfigItem("YAW_ANGLE_MAX_ALERT_THRESHOLD", "number");
        PITCH_ANGLE_MIN_EMOTION_FILTER_THRESHOLD = await getConfigItem("PITCH_ANGLE_MIN_EMOTION_FILTER_THRESHOLD", "number");
        PITCH_ANGLE_MAX_EMOTION_FILTER_THRESHOLD = await getConfigItem("PITCH_ANGLE_MAX_EMOTION_FILTER_THRESHOLD", "number");
        YAW_ANGLE_MIN_EMOTION_FILTER_THRESHOLD = await getConfigItem("YAW_ANGLE_MIN_EMOTION_FILTER_THRESHOLD", "number");
        YAW_ANGLE_MAX_EMOTION_FILTER_THRESHOLD = await getConfigItem("YAW_ANGLE_MAX_EMOTION_FILTER_THRESHOLD", "number");
        ROLL_ANGLE_MIN_EMOTION_FILTER_THRESHOLD = await getConfigItem("ROLL_ANGLE_MIN_EMOTION_FILTER_THRESHOLD", "number");
        ROLL_ANGLE_MAX_EMOTION_FILTER_THRESHOLD = await getConfigItem("ROLL_ANGLE_MAX_EMOTION_FILTER_THRESHOLD", "number");
        FILTER_BOX_SIZE = await getConfigItem("FILTER_BOX_SIZE", "object");
        HAND_POINTS_ON_FACE_THRESHOLD = await getConfigItem("HAND_POINTS_ON_FACE_THRESHOLD", "number");

        FACE_ID_MIN_DISTANCE = await getConfigItem("FACE_ID_MIN_DISTANCE", "number");

        DETECTION_SCORE_THRESHOLD = await getConfigItem("DETECTION_SCORE_THRESHOLD", "number");

        HEAD_ANGLE_EMOTION_FILTER_ACTIVE = await getConfigItem("HEAD_ANGLE_EMOTION_FILTER_ACTIVE", "boolean");
        FACE_CERTAINTY_FRAMES = await getConfigItem("FACE_CERTAINTY_FRAMES", "number");

        BLINK_FREQUENCY_DURATION_SECONDS = await getConfigItem("BLINK_FREQUENCY_DURATION_SECONDS", "number");
        BLINK_FREQUENCY_FILTER_X_FIRST_SECONDS = await getConfigItem("BLINK_FREQUENCY_FILTER_X_FIRST_SECONDS", "number");
        MASK_ON_CERTAINTY_THRESHOLD = await getConfigItem("MASK_ON_CERTAINTY_THRESHOLD", "number");

        MAX_VIDEO_UPLOAD_MINUTES = await getConfigItem("MAX_VIDEO_UPLOAD_MINUTES", "number");

        VIDEO_BACKGROUND_PROCESSING_MIN_SECONDS = await getConfigItem("VIDEO_BACKGROUND_PROCESSING_MIN_SECONDS", "number");
        VIDEO_DASHBOARD_ENALBLE_REALTIME_ANALYSIS = await getConfigItem("VIDEO_DASHBOARD_ENALBLE_REALTIME_ANALYSIS", "boolean");
        initConfig({

            ENERGY_CONVERSION_FACTOR_NEUTRAL_V2: ENERGY_CONVERSION_FACTOR_NEUTRAL,
            ENERGY_CONVERSION_FACTOR_HAPPINESS_V2: ENERGY_CONVERSION_FACTOR_HAPPINESS,
            ENERGY_CONVERSION_FACTOR_DISGUSTED,
            ENERGY_CONVERSION_FACTOR_SAD,
            ENERGY_CONVERSION_FACTOR_ANGRY,
            ENERGY_CONVERSION_FACTOR_FEARFUL,
            ENERGY_CONVERSION_FACTOR_SURPRISED,
            VALENCE_CONVERSION_FACTOR_NEUTRAL,
            VALENCE_CONVERSION_FACTOR_HAPPINESS_V2: VALENCE_CONVERSION_FACTOR_HAPPINESS,
            VALENCE_CONVERSION_FACTOR_DISGUSTED,
            VALENCE_CONVERSION_FACTOR_SAD,
            VALENCE_CONVERSION_FACTOR_ANGRY,
            VALENCE_CONVERSION_FACTOR_FEARFUL,
            VALENCE_CONVERSION_FACTOR_SURPRISED,


            STRESS_CONVERSION_FACTOR_ANGRY,
            STRESS_CONVERSION_FACTOR_FEAR,
            STRESS_CONVERSION_FACTOR_NEUTRAL,
            STRESS_CONVERSION_FACTOR_HAPPINESS,
            STRESS_CONVERSION_FACTOR_SAD,
            STRESS_CONVERSION_FACTOR_SURPRISED,
            STRESS_CONVERSION_FACTOR_DISGUSTED,

            WELLBEING_CONVERSION_FACTOR_HAPPINESS,
            WELLBEING_CONVERSION_FACTOR_NEUTRAL,
            WELLBEING_CONVERSION_FACTOR_ANGRY,
            WELLBEING_CONVERSION_FACTOR_FEAR,
            WELLBEING_CONVERSION_FACTOR_SAD,
            WELLBEING_CONVERSION_FACTOR_SURPRISED,
            WELLBEING_CONVERSION_FACTOR_DISGUSTED,
            ENGAGEMENT_CONVERSION_FACTOR_HAPPINESS,
            ENGAGEMENT_CONVERSION_FACTOR_SAD,
            ENGAGEMENT_CONVERSION_FACTOR_SURPRISED,
            ENGAGEMENT_CONVERSION_FACTOR_FEAR,
            ENGAGEMENT_CONVERSION_FACTOR_DISGUSTED,
            ENGAGEMENT_CONVERSION_FACTOR_NEUTRAL,
            ENGAGEMENT_CONVERSION_FACTOR_ANGRY,
            INTEREST_CONVERSION_FACTOR_HAPPINESS,
            INTEREST_CONVERSION_FACTOR_SAD,
            INTEREST_CONVERSION_FACTOR_SURPRISED,
            INTEREST_CONVERSION_FACTOR_FEAR,
            INTEREST_CONVERSION_FACTOR_DISGUSTED,
            INTEREST_CONVERSION_FACTOR_NEUTRAL,
            INTEREST_CONVERSION_FACTOR_ANGRY
        })


    })
    .catch((err) => {
        console.error(err);
    });


const getConfigItem = async (key, type) => {
    let item = await remoteConfig.getValue(key);
    switch (type) {
        case "number":
            return item.asNumber();
        case "boolean":
            return item.asBoolean();
        case "object":
            return JSON.parse(item.asString());

        default:
            return item;
    }
};

export {
    HAPPINESS_MIN_IN,
    HAPPINESS_MIN_OUT,
    HAPPINESS_MAX_IN,
    HAPPINESS_MAX_OUT,
    HAPPINESS_CERTAINTY_THRESHOLD,
    NEUTRAL_MIN_IN,
    NEUTRAL_MIN_OUT,
    NEUTRAL_MAX_IN,
    NEUTRAL_MAX_OUT,
    NEUTRAL_CERTAINTY_THRESHOLD,
    EMOTIONAL_MIN_IN,
    EMOTIONAL_MIN_OUT,
    EMOTIONAL_MAX_IN,
    EMOTIONAL_MAX_OUT,
    SADNESS_CERTAINTY_THRESHOLD,
    ANGRY_CERTAINTY_THRESHOLD,
    SOLO_WEB_PASSWORD,
    BASELINE_DURATION_SECONDS,
    INITIAL_PLAYLIST_SIZE,
    BASELINE_HAPPINESS_MIN_IN,
    BASELINE_HAPPINESS_MIN_OUT,
    BASELINE_HAPPINESS_MAX_IN,
    BASELINE_HAPPINESS_MAX_OUT,
    BASELINE_HAPPINESS_CERTAINTY_THRESHOLD,
    BASELINE_NEUTRAL_MIN_IN,
    BASELINE_NEUTRAL_MIN_OUT,
    BASELINE_NEUTRAL_MAX_IN,
    BASELINE_NEUTRAL_MAX_OUT,
    BASELINE_NEUTRAL_CERTAINTY_THRESHOLD,
    BASELINE_EMOTIONAL_MIN_IN,
    BASELINE_EMOTIONAL_MIN_OUT,
    BASELINE_EMOTIONAL_MAX_IN,
    BASELINE_EMOTIONAL_MAX_OUT,
    BASELINE_SADNESS_CERTAINTY_THRESHOLD,
    BASELINE_ANGRY_CERTAINTY_THRESHOLD,
    CONVERSION_FACTOR_CALMNESS,
    MAX_VAL_PROGRESS_BAR,
    MAX_VAL_PROGRESS_BAR_RES,
    STRESS_HIGH,
    STRESS_MEDIUM,
    STRESS_LOW,
    INTEREST_HIGH,
    INTEREST_MEDIUM,
    ENGAGEMENT_HIGH,
    ENGAGEMENT_MEDIUM,
    WELLBEING_HIGH,
    WELLBEING_MEDIUM,
    MOOD_HIGH,
    MOOD_MEDIUM,
    ENERGY_HIGH,
    ENERGY_MEDIUM,
    OVER_TIME_MIN_SUFFICIENT_PERCENT,
    DASHBOARD_TIME_FILTER,
    EMOTION_PROGRESS_PERCENT,
    ENERGY_CONVERSION_FACTOR_DISGUSTED,
    ENERGY_CONVERSION_FACTOR_SAD,
    ENERGY_CONVERSION_FACTOR_ANGRY,
    ENERGY_CONVERSION_FACTOR_FEARFUL,
    ENERGY_CONVERSION_FACTOR_SURPRISED,
    VALENCE_CONVERSION_FACTOR_NEUTRAL,
    VALENCE_CONVERSION_FACTOR_DISGUSTED,
    VALENCE_CONVERSION_FACTOR_SAD,
    VALENCE_CONVERSION_FACTOR_ANGRY,
    VALENCE_CONVERSION_FACTOR_FEARFUL,
    VALENCE_CONVERSION_FACTOR_SURPRISED,
    VALENCE_CONVERSION_FACTOR_HAPPINESS,
    ENERGY_CONVERSION_FACTOR_HAPPINESS,
    ENERGY_CONVERSION_FACTOR_NEUTRAL,
    WELLBEING_CONVERSION_FACTOR_HAPPINESS,
    WELLBEING_CONVERSION_FACTOR_NEUTRAL,
    WELLBEING_CONVERSION_FACTOR_ANGRY,
    WELLBEING_CONVERSION_FACTOR_FEAR,
    WELLBEING_CONVERSION_FACTOR_SAD,
    WELLBEING_CONVERSION_FACTOR_SURPRISED,
    WELLBEING_CONVERSION_FACTOR_DISGUSTED,
    STRESS_CONVERSION_FACTOR_HAPPINESS,
    STRESS_CONVERSION_FACTOR_SAD,
    STRESS_CONVERSION_FACTOR_SURPRISED,
    STRESS_CONVERSION_FACTOR_FEAR,
    STRESS_CONVERSION_FACTOR_DISGUSTED,
    STRESS_CONVERSION_FACTOR_NEUTRAL,
    STRESS_CONVERSION_FACTOR_ANGRY,
    INTEREST_CONVERSION_FACTOR_HAPPINESS,
    INTEREST_CONVERSION_FACTOR_SAD,
    INTEREST_CONVERSION_FACTOR_SURPRISED,
    INTEREST_CONVERSION_FACTOR_FEAR,
    INTEREST_CONVERSION_FACTOR_DISGUSTED,
    INTEREST_CONVERSION_FACTOR_NEUTRAL,
    INTEREST_CONVERSION_FACTOR_ANGRY,
    ENGAGEMENT_CONVERSION_FACTOR_HAPPINESS,
    ENGAGEMENT_CONVERSION_FACTOR_SAD,
    ENGAGEMENT_CONVERSION_FACTOR_SURPRISED,
    ENGAGEMENT_CONVERSION_FACTOR_FEAR,
    ENGAGEMENT_CONVERSION_FACTOR_DISGUSTED,
    ENGAGEMENT_CONVERSION_FACTOR_NEUTRAL,
    ENGAGEMENT_CONVERSION_FACTOR_ANGRY,
    YAWN_CERTAINTY_THRESHOLD,
    YAWN_TIREDNESS_TIME_THRESHOLD,
    EYE_CLOSED_CERTAINTY_THRESHOLD,
    EYE_CLOSED_ASLEEP_TIME_THRESHOLD,
    EYE_BLINKED_CERTAINTY_THRESHOLD,
    YAW_ANGLE_ALERT_THRESHOLD,
    PITCH_ANGLE_MIN_ALERT_THRESHOLD,
    PITCH_ANGLE_MAX_ALERT_THRESHOLD,
    YAW_ANGLE_MIN_ALERT_THRESHOLD,
    YAW_ANGLE_MAX_ALERT_THRESHOLD,
    PITCH_ANGLE_MIN_EMOTION_FILTER_THRESHOLD,
    PITCH_ANGLE_MAX_EMOTION_FILTER_THRESHOLD,
    YAW_ANGLE_MIN_EMOTION_FILTER_THRESHOLD,
    YAW_ANGLE_MAX_EMOTION_FILTER_THRESHOLD,
    ROLL_ANGLE_MIN_EMOTION_FILTER_THRESHOLD,
    ROLL_ANGLE_MAX_EMOTION_FILTER_THRESHOLD,
    FILTER_BOX_SIZE,
    HAND_POINTS_ON_FACE_THRESHOLD,
    FACE_ID_MIN_DISTANCE,
    DETECTION_SCORE_THRESHOLD,
    HEAD_ANGLE_EMOTION_FILTER_ACTIVE,
    FACE_CERTAINTY_FRAMES,
    BLINK_FREQUENCY_DURATION_SECONDS,
    BLINK_FREQUENCY_FILTER_X_FIRST_SECONDS,
    MASK_ON_CERTAINTY_THRESHOLD,
    MAX_VIDEO_UPLOAD_MINUTES,
    VIDEO_BACKGROUND_PROCESSING_MIN_SECONDS,
    VIDEO_DASHBOARD_ENALBLE_REALTIME_ANALYSIS
}
