import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { OverTimeButton } from "../../StyledComponents";
import OverTimeChart from '../../components/Charts/OverTime';
import { getString } from "../../utils/translations";
import { findIndex, get, cloneDeep } from 'lodash';
import {OVER_TIME_BUTTONS} from "../../constants";
import {FACE_CERTAINTY_FRAMES} from "../../api/remoteConfig";
import IdButtons from "../../components/IdButtons";
import colors from "../../styles/colors";

const OVERTIME_CHART_HEIGHT = 250 + 60

const SoloOverTimeChart = ({
                               overtime,
                               faceIds,
                               faceIdCount,
                               selectedIds,
                               showBrush,
                               onBrushChange,
                               toggleFaceLandmarks,
                               resetOverTime,
                               selectId,
                               showFaceLandmarks,
                               isJP,
                               showAll = true,
                               showPartTime = 30
                           }) => {
    const [selectedButtons, setSelectedButtons] = useState([OVER_TIME_BUTTONS.STRESS]);
    const [chartShowAllTime, setChartShowAllTime] = useState(showAll);

    const [dynamicWidth, setDynamicWidth] = useState("60%");

    const otButtons1Container = useRef(null);
    const otButtons2Container = useRef(null);
    const otButtons3Container = useRef(null);

    const updateOTButtonsWidth = () => {
        if (otButtons1Container.current && otButtons3Container.current && otButtons2Container.current) {
            const parentWidth = otButtons2Container.current.parentElement.clientWidth;
            const div1Width = otButtons1Container.current.clientWidth;
            const div3Width = otButtons3Container.current.clientWidth;
            const newDiv2Width = parentWidth - div1Width - div3Width - 30;
            setDynamicWidth(newDiv2Width);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', updateOTButtonsWidth);
        updateOTButtonsWidth();
        return () => window.removeEventListener('resize', updateOTButtonsWidth);
    }, []);


    const onOvertimeEmotionClick = (emotion) => {
        let i = findIndex(selectedButtons, (value) => value === emotion);
        let update = cloneDeep(selectedButtons);

        if (!selectedButtons.length) {
            update.push(emotion);
        } else {
            if (i === -1) {
                update = [emotion];
            } else {
                update.splice(i, 1);
            }
        }

        setSelectedButtons(update);
    }

    const renderOvertimeButtons = (buttons) => {
        let extraStyle = isJP ? { fontSize: 10 } : {};

        return (
            <div style={{ gap: 10, display: 'flex', flexDirection: 'column' }}>
                {
                    Object.keys(buttons).map((key) =>
                        <OverTimeButton
                            style={Object.assign(selectedButtons.includes(buttons[key]) ? { backgroundColor: colors.blue } : {}, extraStyle)}
                            onClick={() => onOvertimeEmotionClick(buttons[key])}>
                            {getString(buttons[key])}
                        </OverTimeButton>
                    )
                }
            </div>
        );
    }

    const tooltipLabelFormatter = (payload, data, x) => {
        if (data[payload] && data[payload].metadata) {
            return data[payload].metadata.title
        }
        if (x.formatter) {
            return x.formatter(payload)
        }
        return payload;
    }

    const renderIdsButtons = (ids) => {

        const filteredIds = ids.filter(key => faceIdCount[key] >= FACE_CERTAINTY_FRAMES);

        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", width: "100%" }}>
                <div ref={otButtons1Container} style={{ gap: 15, display: 'flex', flexDirection: 'row', }}>
                    {resetOverTime ? (
                        <OverTimeButton style={{ backgroundColor: colors.black, color: colors.white, borderRadius: 0 }}
                                        onClick={resetOverTime}>
                            {getString("reset_data")}
                        </OverTimeButton>
                    ) : null}
                </div>

                <div ref={otButtons2Container} style={{ width: dynamicWidth, marginLeft: 15, marginRight: 15 }}>
                    <IdButtons ids={filteredIds} selectedIds={selectedIds} selectId={selectId} />
                </div>

                <div ref={otButtons3Container} style={{ gap: 15, display: 'flex', flexDirection: 'row', }}>
                    <OverTimeButton
                        style={{ backgroundColor: colors.black, color: colors.white, borderRadius: 0, width: 150 }}
                        onClick={() => setChartShowAllTime(!chartShowAllTime)}>
                        {chartShowAllTime ? getString("chart_show_all_time") : getString("chart_show_30")}
                    </OverTimeButton>
                    <OverTimeButton
                        style={{ backgroundColor: colors.black, color: colors.white, borderRadius: 0, width: 150 }}
                        onClick={toggleFaceLandmarks}>
                        {showFaceLandmarks ? getString("hide_landmarks") : getString("show_landmarks")}
                    </OverTimeButton>
                </div>
            </div>
        );
    }

    const chartData = useMemo(() => {
        if (!overtime || overtime.length === 0) return [];
        
        let data = [...overtime];
        
        if (!chartShowAllTime && data.length > 1) {
            const totalTimeSpent = data[data.length - 1].timeSpent;
            const timeThreshold = totalTimeSpent - showPartTime * 1000;
            const startIndex = data.findIndex(item => item.timeSpent >= timeThreshold);
            if (startIndex !== -1) {
                data = data.slice(startIndex);
            }
        }
        
        return data;
    }, [overtime, chartShowAllTime, showPartTime]);

    const chartDomain = useMemo(() => {
        let domain = [0, 100];
        
        if (selectedButtons.length === 1) {
            if (selectedButtons[0] === OVER_TIME_BUTTONS.YAW_ANGLE) {
                domain = [-50, 50];
            } else if (selectedButtons[0] === OVER_TIME_BUTTONS.PITCH_ANGLE) {
                domain = [-60, 60];
            } else if (selectedButtons[0] === OVER_TIME_BUTTONS.FROWN) {
                domain = [-100, 100];
            } else if (selectedButtons[0] === OVER_TIME_BUTTONS.YAWN_RATIO) {
                domain = [0, 100];
            } else if (selectedButtons[0] === OVER_TIME_BUTTONS.FROWN_SCORE) {
                domain = [0, 100];
            } else if (selectedButtons[0] === OVER_TIME_BUTTONS.FROWN_NOSE) {
                domain = [300, 350];
            }
        }
        
        return domain;
    }, [selectedButtons]);

    const toggleChartTimeDisplay = useCallback(() => {
        setChartShowAllTime(prev => !prev);
    }, []);

    const MemoizedOvertimeButtons = useCallback(
        (buttons) => {
            let extraStyle = isJP ? { fontSize: 10 } : {};

            return (
                <div style={{ gap: 10, display: 'flex', flexDirection: 'column' }}>
                    {
                        Object.keys(buttons).map((key) =>
                            <OverTimeButton
                                key={buttons[key]}
                                style={Object.assign(selectedButtons.includes(buttons[key]) ? { backgroundColor: colors.blue } : {}, extraStyle)}
                                onClick={() => onOvertimeEmotionClick(buttons[key])}>
                                {getString(buttons[key])}
                            </OverTimeButton>
                        )
                    }
                </div>
            );
        },
        [selectedButtons, isJP]
    );

    return (
        <div style={{ height: OVERTIME_CHART_HEIGHT, position: "absolute", left: 0, right: 0, bottom: 0 }}>
            <div style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 12,
                flexDirection: 'column'
            }}>
                <div style={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
                    {renderIdsButtons(faceIds)}
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    marginTop: 10
                }}>
                    {MemoizedOvertimeButtons([OVER_TIME_BUTTONS.MOOD, OVER_TIME_BUTTONS.ENERGY, OVER_TIME_BUTTONS.STRESS, OVER_TIME_BUTTONS.ENGAGEMENT])}
                    <div style={{ width: '100%', height: '100%', }}>
                        <OverTimeChart
                            x={{ key: "index" }}
                            y={{ key: "value" }}
                            data={chartData}
                            selectedEmotions={selectedButtons}
                            tooltipLabelFormatter={tooltipLabelFormatter}
                            domain={chartDomain}
                            selectedIds={selectedIds}
                            showBrush={showBrush}
                            onBrushChange={onBrushChange}
                        />
                    </div>
                    {MemoizedOvertimeButtons([OVER_TIME_BUTTONS.YAWN_RATIO, OVER_TIME_BUTTONS.EYES, OVER_TIME_BUTTONS.YAW_ANGLE, OVER_TIME_BUTTONS.PITCH_ANGLE, OVER_TIME_BUTTONS.FROWN])}
                </div>
            </div>
        </div>
    );
}

export default React.memo(SoloOverTimeChart);
