import {configureStore} from "@reduxjs/toolkit";
import {routerMiddleware} from 'connected-react-router'
import {history} from './root-reducer'
import {createRootReducer} from "./root-reducer"
import {houseListenerMiddleware} from "./house/middleware";
import {orgListenerMiddleware} from "./organization/middleware";
import {translationsListenerMiddleware} from "./translations/middleware";
import { videoListenerMiddleware } from './video/middleware';

console.log('Creating store with video middleware');

const store = configureStore({
    reducer: createRootReducer(history),
    middleware: (getDefaultMiddleware) => {
        console.log('Setting up middleware');
        return getDefaultMiddleware({serializableCheck: false})
            .prepend(routerMiddleware(history))
            .prepend(houseListenerMiddleware.middleware)
            .prepend(orgListenerMiddleware.middleware)
            .prepend(translationsListenerMiddleware.middleware)
            .prepend(videoListenerMiddleware.middleware);
    }
});

export default store;
