import { createListenerMiddleware } from '@reduxjs/toolkit';
import { updateVideoMap } from './index';
import { get } from 'lodash';

console.log('Initializing video middleware');

// Create the middleware instance
export const videoListenerMiddleware = createListenerMiddleware();

// Add a simple listener that doesn't depend on external actions
videoListenerMiddleware.startListening({
  actionCreator: updateVideoMap,
  effect: (action) => {
    console.log('Video map updated:', action.payload);
  }
});

// Create a function to initialize the video map from table data
export const initializeVideoMap = (tableData) => (dispatch) => {
  console.log('Initializing video map from table data');
  const videos = tableData || [];
  const videoMap = {};
  
  videos.forEach(video => {
    if (video.name && video.videoId) {
      videoMap[video.name.toLowerCase()] = video.videoId;
    }
  });
  
  console.log('Created video map:', videoMap);
  dispatch(updateVideoMap(videoMap));
};