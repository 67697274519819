import React from 'react';
import styled from 'styled-components';
import { getAsset } from '../../utils';
import { getString } from '../../utils/translations';

const ChatButton = ({ onClick, isOpen }) => {
    return (
        <ChatButtonContainer onClick={onClick}>
            {!isOpen && <span>{getString("ai_assistant")}</span>}
            <img src={getAsset(isOpen ? 'icons/close.svg' : 'icons/Sent-black.svg')} alt={isOpen ? 'Close' : 'Chat'} />
        </ChatButtonContainer>
    );
};

const ChatButtonContainer = styled.div`
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: #FFBD58;
    color: white;
    border-radius: 50px;
    padding: 12px 24px;
    display: flex;
    border-radius: 11.613px;    
    align-items: center;
    gap: 10px;
    cursor: pointer;
    z-index: 100;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 4.645px 0px rgba(0, 0, 0, 0.25);
    
    &:hover {
        transform: translateY(-2px);
        box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.3);
    }
    
    img {
        width: 24px;
        height: 24px;
    }
    
    span {
        font-weight: bold;
        font-family: 'Open Sans';
        font-size: 16px;
        color: #2E384D;
    }
`;

export default ChatButton; 