import { createSlice } from '@reduxjs/toolkit';

console.log('Loading video reducer');

const initialState = {
    videoMap: {},  // Will store { videoName: videoId }
    videoReady: false, // Keep existing state properties
    videoTracker: [] // Keep existing state properties
};

const videoSlice = createSlice({
    name: 'video',
    initialState,
    reducers: {
        updateVideoMap: (state, action) => {
            state.videoMap = action.payload;
        },
        addVideo: (state, action) => {
            const { name, videoId } = action.payload;
            state.videoMap[name.toLowerCase()] = videoId;
        },
        removeVideo: (state, action) => {
            const { name } = action.payload;
            delete state.videoMap[name.toLowerCase()];
        },
        // Keep existing reducers
        initVideoReady: (state, action) => {
            state.videoReady = action.payload;
        },
        setVideoTracker: (state, action) => {
            state.videoTracker = action.payload;
        }
    },
});

export const { 
    updateVideoMap, 
    addVideo, 
    removeVideo,
    initVideoReady,
    setVideoTracker 
} = videoSlice.actions;

export default videoSlice.reducer; 