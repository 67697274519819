import { 
    addUserMessage, 
    addAIMessage, 
    addSystemMessage,
    setLoading,
    setLoadingStatus,
    setError,
    setCurrentVideoId,
    setChatExists,
    clearMessages,
    addSuccessMessage
} from './index';
import { 
    initializeChat, 
    continueChat, 
    getChatStatus, 
    getChatHistory,
    resetChat as apiResetChat,
    stopChat as apiStopChat
} from '../../api/solo';
import { get } from 'lodash';

// Dictionary of user-friendly status messages
const statusMessages = {
    initializing: "Preparing to analyze your video...",
    processing: "Processing video data (this may take a minute)...",
    in_progress: "Analyzing emotions and facial expressions...",
    processing_follow_up: "Processing your follow-up question..."
};

// Replace the simple boolean token with a more robust mechanism
let activePollingTimeouts = [];

// Add an abort controller to handle in-flight requests
let abortController = null;
let isPollingActive = false; // Flag to track if polling is active

// Keep track of the current request ID
let currentRequestId = null;

// Check if a chat exists for the current house
export const checkChatStatus = () => async (dispatch) => {
    try {
        const response = await getChatStatus();
        
        if (response.status && response.status !== 'not_initialized') {
            dispatch(setChatExists(true));
            
            if (response.videoId) {
                dispatch(setCurrentVideoId(response.videoId));
            }
            
            return true;
        } else {
            dispatch(setChatExists(false));
            return false;
        }
    } catch (error) {
        console.error('Error checking chat status:', error);
        dispatch(setChatExists(false));
        return false;
    }
};

// Load chat history for the current house
export const loadChatHistory = () => async (dispatch) => {
    try {
        const history = await getChatHistory();
        
        if (history.messages && history.messages.length > 0) {
            // Clear existing messages
            dispatch(clearMessages());
            
            // Set current video ID
            if (history.videoId) {
                dispatch(setCurrentVideoId(history.videoId));
            }
            
            // Reverse the messages array to get chronological order (oldest first)
            const chronologicalMessages = [...history.messages].reverse();
            
            // Add each message to the chat in chronological order
            chronologicalMessages.forEach(msg => {
                if (msg.role === 'user') {
                    dispatch(addUserMessage(msg.content));
                } else if (msg.role === 'assistant') {
                    dispatch(addAIMessage(msg.content));
                }
            });
            
            dispatch(setChatExists(true));
        } else {
            dispatch(setChatExists(false));
        }
    } catch (error) {
        console.error('Error loading chat history:', error);
        dispatch(setChatExists(false));
    }
};

// Send a chat message
export const sendChatMessage = (message) => async (dispatch, getState) => {
    try {
        // Generate a unique request ID for this message
        currentRequestId = Date.now().toString();
        const thisRequestId = currentRequestId;
        
        dispatch(addUserMessage(message));
        dispatch(setLoading(true));
        
        const state = getState();
        const chatExists = get(state, 'chat.chatExists', false);
        const currentVideoId = get(state, 'chat.currentVideoId', null);
        const tableData = get(state, 'table.tableData', []);
        
        // Check if message contains a video reference
        const videoInfo = findVideoInTableData(message, tableData);
        
        console.log('Sending chat message:', { 
            message, 
            chatExists, 
            currentVideoId,
            newVideoReferenced: !!videoInfo
        });
        
        if (chatExists) {
            // This is a follow-up question
            dispatch(setLoadingStatus('processing_follow_up'));
            
            try {
                // Use continueChat for follow-up questions, passing videoId if a new video is referenced
                const response = await continueChat(
                    message, 
                    videoInfo ? videoInfo.videoId : null
                );
                
                console.log('Continue chat response:', response);
                
                // If a new video is referenced, update the current video ID
                if (videoInfo && response.videoId) {
                    dispatch(setCurrentVideoId(response.videoId));
                }
                
                if (response.status === 'completed' && response.message) {
                    dispatch(addAIMessage(response.message));
                    dispatch(setLoading(false));
                    return;
                }
                
                // Check if this request is still valid before starting polling
                if (thisRequestId === currentRequestId) {
                    await pollForCompletion(dispatch, thisRequestId);
                }
                
            } catch (error) {
                console.error('Error continuing chat:', error);
                throw error;
            }
        } else {
            // Initial question needs video identification
            if (!videoInfo) {
                dispatch(addSystemMessage('Could not identify which video you are asking about. Please mention a video name in your question or use the video selector.'));
                dispatch(setLoading(false));
                return;
            }
            
            try {
                // Initialize a new chat with the video
                dispatch(setLoadingStatus('initializing'));
                console.log('Initializing chat with video:', videoInfo);
                
                const response = await initializeChat(videoInfo.videoId, message);
                console.log('Initialize chat response:', response);
                
                if (response.videoId) {
                    dispatch(setCurrentVideoId(response.videoId));
                }
                
                dispatch(setChatExists(true));
                
                if (response.status === 'completed' && response.message) {
                    dispatch(addAIMessage(response.message));
                    dispatch(setLoading(false));
                    return;
                }
                
                // Update loading status
                dispatch(setLoadingStatus(response.status || 'processing'));
                
                // Check if this request is still valid before starting polling
                if (thisRequestId === currentRequestId) {
                    await pollForCompletion(dispatch, thisRequestId);
                }
                
            } catch (error) {
                console.error('Error initializing chat:', error);
                throw error;
            }
        }
    } catch (error) {
        console.error('Error in chat:', error);
        dispatch(setError(error.message));
        dispatch(addSystemMessage('Sorry, there was an error processing your request. Please try again later.'));
        dispatch(setLoading(false));
    }
};

// Find video in tableData - with improved null checking
const findVideoInTableData = (message, tableData) => {
    if (!message || !Array.isArray(tableData) || tableData.length === 0) return null;
    
    // First ensure message is a string
    const messageLower = String(message).toLowerCase();
    
    // Look for quoted video names first - more precise
    const quotedNameRegex = /"([^"]+)"/g;
    const quotes = [...messageLower.matchAll(quotedNameRegex)];
    
    if (quotes.length > 0) {
        for (const match of quotes) {
            const quotedName = match[1].toLowerCase();
            
            for (const video of tableData) {
                // Skip invalid videos
                if (!video) continue;
                
                // Safely get the video name with a default empty string if null/undefined
                const videoName = (get(video, 'name') || '').toLowerCase();
                
                // Only compare if videoName is not empty
                if (videoName && (videoName === quotedName || videoName.includes(quotedName) || quotedName.includes(videoName))) {
                    return { 
                        videoId: get(video, 'id') || get(video, 'videoId'), 
                        name: get(video, 'name', '') 
                    };
                }
            }
        }
    }
    
    // If no quoted match, try to find any video name in the message
    for (const video of tableData) {
        // Skip invalid videos
        if (!video) continue;
        
        // Safely get the video name with a default empty string if null/undefined
        const videoName = (get(video, 'name') || '').toLowerCase();
        
        // Only check if videoName is not empty and has minimum length
        if (videoName && videoName.length > 3 && messageLower.includes(videoName)) {
            return { 
                videoId: get(video, 'id') || get(video, 'videoId'), 
                name: get(video, 'name', '') 
            };
        }
    }
    
    return null;
};

// Update the pollForCompletion function with abort handling
const pollForCompletion = async (dispatch, requestId) => {
    let attempts = 0;
    const maxAttempts = 60;
    const initialDelay = 2000;
    const maxDelay = 10000;
    
    clearAllPollingTimeouts();
    
    const poll = async () => {
        // First check if this request has been cancelled
        if (requestId !== currentRequestId) {
            console.log(`Polling cancelled - requestId ${requestId} no longer current`);
            return;
        }
        
        if (attempts >= maxAttempts) {
            dispatch(addSystemMessage('Analysis is taking longer than expected. Please try again later.'));
            dispatch(setLoading(false));
            return;
        }
        
        try {
            const response = await getChatStatus();
            
            // Check again if request is still current
            if (requestId !== currentRequestId) {
                console.log(`Ignoring status response - requestId ${requestId} no longer current`);
                return;
            }
            
            // Update loading status if provided
            if (response.status) {
                dispatch(setLoadingStatus(response.status));
            }
            
            if (response.status === 'completed') {
                const aiResponse = response.response || response.message;
                if (aiResponse) {
                    dispatch(addAIMessage(aiResponse));
                } else {
                    dispatch(addSystemMessage('Analysis completed but no response was provided.'));
                }
                dispatch(setLoading(false));
                return;
            }
            
            if (response.status === 'failed' || response.status === 'error') {
                dispatch(addSystemMessage(response.error || 'There was an error processing your request.'));
                dispatch(setLoading(false));
                return;
            }
            
            // Still in progress, schedule next poll
            attempts++;
            
            // Calculate adaptive delay based on status
            let delay;
            if (['initializing', 'processing'].includes(response.status)) {
                delay = initialDelay;
            } else {
                delay = Math.min(initialDelay * Math.pow(1.5, attempts), maxDelay);
            }
            
            // When scheduling next poll, add the check again
            if (requestId === currentRequestId) {
                const timeoutId = setTimeout(() => poll(), delay);
                activePollingTimeouts.push(timeoutId);
            }
        } catch (error) {
            // Check if this is an abort error (user cancelled)
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                return;
            }
            
            console.error('Error during status polling:', error);
            
            // Only continue polling if it's still active
            if (requestId === currentRequestId) {
                attempts++;
                const delay = Math.min(initialDelay * Math.pow(2, attempts), maxDelay);
                const timeoutId = setTimeout(() => poll(), delay);
                activePollingTimeouts.push(timeoutId);
            }
        }
    };
    
    await poll();
};

// Helper function to make an abortable status request
const getStatusWithAbort = async (signal) => {
    try {
        // We can't directly modify the imported getChatStatus function
        // So let's create a wrapper with our own fetch implementation
        const controller = new AbortController();
        const originalSignal = signal;
        
        // Wrap the existing function in a Promise that can be aborted
        const fetchPromise = new Promise((resolve, reject) => {
            // Use the imported function
            getChatStatus()
                .then(result => {
                    if (controller.signal.aborted) {
                        reject(new DOMException('Aborted', 'AbortError'));
                    } else {
                        resolve(result);
                    }
                })
                .catch(error => reject(error));
            
            // Listen for abort signal
            originalSignal.addEventListener('abort', () => {
                controller.abort();
                reject(new DOMException('Aborted', 'AbortError'));
            });
        });
        
        return await fetchPromise;
    } catch (error) {
        throw error;
    }
};

// Helper function to clear all active polling timeouts
const clearAllPollingTimeouts = () => {
    console.log(`Clearing ${activePollingTimeouts.length} active polling timeouts`);
    
    // Clear each timeout
    activePollingTimeouts.forEach(timeoutId => {
        clearTimeout(timeoutId);
    });
    
    // Empty the array
    activePollingTimeouts = [];
};

// Update the stopPolling function to be more robust
const stopPolling = () => {
    console.log('Stopping all polling activity');
    
    // Set the flag to stop polling
    isPollingActive = false;
    
    // Clear all scheduled timeouts
    clearAllPollingTimeouts();
    
    // Abort any in-flight requests
    if (abortController) {
        abortController.abort();
        abortController = null;
    }
};

// Update the resetChatAction to cancel the server-side operation
export const resetChatAction = () => async (dispatch) => {
    // Cancel any active polling first
    stopPolling();
    
    // Reset loading state immediately
    dispatch(setLoading(false));
    
    try {
        console.log('Resetting chat and cancelling any pending requests');
        
        // Reset the current request ID to prevent further handling of responses
        currentRequestId = null;
        
        const response = await apiResetChat();
        
        if (response.success) {
            dispatch(clearMessages());
            dispatch(setCurrentVideoId(null));
            dispatch(setChatExists(false));
            dispatch(addSuccessMessage('Chat has been reset. You can now start a new conversation.'));
        } else {
            dispatch(addSystemMessage('Failed to reset chat. Please try again.'));
        }
    } catch (error) {
        console.error('Error resetting chat:', error);
        dispatch(addSystemMessage('Error resetting chat. Please try again later.'));
    }
};

// Add this new action
export const stopChatProcessing = () => async (dispatch) => {
    // Cancel any active polling first
    stopPolling();
    
    // Reset loading state immediately
    dispatch(setLoading(false));
    
    try {
        console.log('Stopping chat processing...');
        
        // Reset the current request ID to prevent further handling of responses
        currentRequestId = null;
        
        const response = await apiStopChat();
        
        if (response.success) {
            dispatch(addSystemMessage(response.message || 'Chat processing has been stopped. You can continue this chat later.'));
        } else {
            dispatch(addSystemMessage(response.message || 'No active chat processing found to stop.'));
        }
    } catch (error) {
        console.error('Error stopping chat:', error);
        dispatch(addSystemMessage('Error stopping chat processing. Please try again later.'));
    }
}; 